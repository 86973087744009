$grayReg: #3a3a3a;
$grayLight: #636363;
$greenReg: #8ec927;
$grayLighter: #adadad;
$grayLightest: #cacaca;
$greenLight: #9cd932;
$blueReg: #478dd9;
$blueLight: #5e9ee4;
$purple: #7745AD;
$purpleReg: #54137e;
$purpleLight: #7b31a9;
$attention: #c30000;
$warning: #f4b81f;
$red: #ff5a45;
$caution: #f3d76b;

// Primary Colors
$primaryColor: #8553BA;
$secondaryColor: #9c6ccf;
$textColor: #551A8B;

// Sidenav Colors
$primaryNavPersonal: #3a87ad;
$primaryNavColor: #824eb8;
$primaryNavHighlight: #6d37a4;
$primaryNavBackground: #fff;

// Logo
.logo {background: url(/images/logo-admin.png) 0 7px no-repeat;}
.loginlogo {
  background: url('/images/logo-admin-pp.png') center top no-repeat;
  background-size: 100% auto;
  width:176px;
  height: 28px;
  margin: 0 auto 20px;
  overflow: hidden;
}
#orderInfo {
    background: $primaryColor;
    background: -moz-linear-gradient(-45deg,  $primaryColor 0%, darken($primaryColor, 10%) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,$primaryColor), color-stop(100%, darken($primaryColor, 10%)));
    background: -webkit-linear-gradient(-45deg,  $primaryColor 0%,darken($primaryColor, 10%) 100%);
    background: linear-gradient(135deg,  $primaryColor 0%,darken($primaryColor, 10%) 100%);
    color: lighten($primaryColor, 20%);
}
#maincontent .roundbtn, .modal .roundbtn {
  background: #0077b3;
  border: none;
}
#contentTop span {color: $textColor;}
// end
