﻿.footable {
	border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: solid #ccc 1px;
	font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #444;
}

.footable tr:nth-child(even) { background-color: #e6e7e7;}

.footable.breakpoint > tbody > tr > td.expand:before {
  content: "\23"; font-family: 'primoadmin'; opacity: .5; position: absolute; left: 5px; @extend .vertical-align;
}

.footable.breakpoint > tbody > tr.footable-detail-show > td.expand:before {
  content: "\22"; font-family: 'primoadmin'; opacity: .5; position: absolute; left: 5px; @extend .vertical-align;
}

.footable.breakpoint > tbody > tr.footable-row-detail {
  background: #eee;
}

.footable > tbody > tr:hover {
  background: #fbf8e9;
}

.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}

.footable > tbody > tr > td {
  border-top: 1px solid #ccc;
  padding: 5px 10px;
  text-align: center;
  position: relative;
}

.footable-row-detail-inner {text-align: left}
.footable-row-detail-name {display: inline-block; margin-right: 10px; font-weight: bold; min-width: 25%}
.footable-row-detail-value {display: inline-block;}

.footable > thead > tr > th {
  padding: 10px 3px;
  color: #ea9b00;
}

.footable.subtle > thead, .footable.subtle > thead > tr, .footable.subtle > thead > tr > th  {
  border: none !important
}

.footable.subtle > thead > tr > th {
  background: #efefef;
  color: #444;
}

.footable > tbody > tr > td.footable-cell-detail {
  border-left: none;
  text-align: left;
}

.footable > thead > tr > th, .footable > thead > tr > td {
  background: url(/images/texture01.jpg);
  //background-color: #2b2b2b;
  border-top: none;
  text-align: center;
}

.footable > thead > tr:first-child > th.footable-first-column, .footable > thead > tr:first-child > td.footable-first-column {

}

.footable > thead > tr:first-child > th.footable-last-column, .footable > thead > tr:first-child > td.footable-last-column {

}

.footable > thead > tr:first-child > th.footable-first-column.footable-last-column, .footable > thead > tr:first-child > td.footable-first-column.footable-last-column {

}

.footable > tbody > tr:last-child > td.footable-first-column {

}

.footable > tbody > tr:last-child > td.footable-last-column {

}

.footable > tbody > tr:last-child > td.footable-first-column.footable-last-column {

}

.footable > thead > tr > th.footable-first-column, .footable > thead > tr > td.footable-first-column,
.footable > tbody > tr > td.footable-first-column {
  border-left: none;
}

.footable.breakpoint > thead > tr > th.footable-first-column, .footable.breakpoint > thead > tr > td.footable-first-column,
.footable.breakpoint > tbody > tr > td.footable-first-column {
  padding-left: 25px;
}
.footable.breakpoint > tbody > tr > td.footable-row-detail-cell.footable-first-column {padding: 5px 10px;}

.footable > tbody img {
  vertical-align:middle;
}

.footable > tfoot > tr > th, .footable > tfoot > tr > td {
  border-top: 1px solid #ccc;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  padding: 10px;
}
