@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin border-radius-custom ($topleft: 5px, $topright: 5px, $bottomright: 5px, $bottomleft: 5px) {
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
  background-clip: padding-box;
}

@mixin box-shadow ($x: 0, $y: 0, $blur: 1px, $color: #000) {
	-moz-box-shadow: $x $y $blur $color;
	-webkit-box-shadow: $x $y $blur $color;
	box-shadow: $x $y $blur $color;
}

@mixin rotate ($rotate: 30deg) {
	-ms-transform: rotate($rotate); /* IE 9 */
	-webkit-transform: rotate($rotate); /* Chrome, Safari, Opera */
	transform: rotate($rotate);
}

.vertical-align {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center-align-fix {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.horizontal-align-fix {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.vertical-align-ab {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-align-ab {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
