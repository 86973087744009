.subnav .header1 {
  background: #824eb8;
  text-transform: capitalize;
  font-size: 14px;
}
.subnav .header1:hover{
  background-color: #6d37a4;
}
.subnav .header1.assigned:hover{
  background-color: #256d8d;
}
.subnav .header1 > a {
  color: #ffffff !important;
}
.subnav .header2 {
  background: #fffbf3;
  border-top: 1px solid #ffffff;
  color: #817272;
  border-bottom: 1px solid #efdbdb;
  text-transform: capitalize;
  font-size: 15px;
}
.submmenu-item {
  display: flex;
}
.submmenu-item > a{
  font-size: 13px !important;
  margin-left: 15px;
  color: #817272 !important;
}
.subnav .header2:hover{
  background-color: #dfd7d7;
  color: #817272;
}
.subnav .header2 > a {
  /*color: #ffffff !important;*/
}

.subnav .header3:hover{
  background-color: #535353;
  color: #ffffff;
}
.subnav .header3 > a {
  color: #ffffff !important;
}
.header1.assigned {
  background: #3a87ad;
}
.header1.assigned:hover  .header1.assigned.active {
  background: #256d8d !important;
}
.header1 > a > span {
  float: right;
}
.subnav .header1.active {
  background: #6d37a4;
}
.subnav .header1.assigned.active {
  background: #256d8d !important;
}
.subnav .header1.assigned.active:after {
  border-left: 7px solid #256d8d !important;
}
.header.active:after {
  content: '';
  position: relative;
  top: -29px;
  float: right;
  right: -37px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left: 7px solid #6d37a4;
  transition: border-left 0.2s;
}
.next_active{
  margin-right : -27px
}