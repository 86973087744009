:root {
  --progress-color-normal: #0bd82c;
  --progress-color-paused: #e6df1b;
  --progress-color-error: #ef0000;
}

[role="progressbar"] {
  height: 15px;
  border: 1px solid var(--button-border-color);
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px #f3f3f388, 0 0 0 1px #eaeaea88;
  margin: 2px 0;
  overflow: hidden;
  background: radial-gradient(circle at 0 50%, #0000001f 10px, transparent 30px),
    radial-gradient(circle at 100% 50%, #0000001f 10px, transparent 30px),
    linear-gradient(
      to bottom,
      #f3f3f3af,
      #fcfcfcaf 3px,
      #dbdbdbaf 6px,
      #cacacaaf 6px,
      #d5d5d5af
    ),
    #ddd;

  > div {
    overflow: hidden;
    height: 100%;
    box-shadow: inset 0 0 0 1px #ffffff1f;
    background-color: var(--progress-color-normal);
    background-image: linear-gradient(
        to bottom,
        #f3f3f3af,
        #fcfcfcaf 3px,
        #dbdbdbaf 6px,
        transparent 6px
      ),
      radial-gradient(circle at 0 50%, #0000002f 10px, transparent 30px),
      radial-gradient(circle at 100% 50%, #0000002f 10px, transparent 30px),
      linear-gradient(to bottom, transparent 65%, #ffffff55),
      linear-gradient(to bottom, transparent 6px, #cacaca33 6px, #d5d5d533);
  }

  &.paused > div {
    background-color: var(--progress-color-paused);
  }

  &.error > div {
    background-color: var(--progress-color-error);
  }

  &.animate > div::before,
  &.marquee::before {
    content: "";
    animation: progressbar 3s linear infinite;
    display: block;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      #ffffff80,
      transparent 40%
    );
  }

  &.marquee::before {
    opacity: 0.5;
    background: linear-gradient(
      to right,
      transparent,
      var(--progress-color-normal),
      transparent 35%
    );
  }
}

@keyframes progressbar {
  0% {
    transform: translateX(-40%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    /* for faking delay */
    transform: translateX(100%);
  }
}
