#table_sub_crons {
  margin-bottom: 0.2rem !important;
  margin-top: 0.1rem !important;
}

#table_users .footable-row-detail-name {
  display: flex !important;
}
#table_users .footable-row-detail-value {
  display: unset !important;
}

.cron-parts {
  display: flex;
  justify-content: space-evenly;
  font-size: 11px;
  margin-top: 0.45rem;
}

.cron-parts div {
  display: ruby;
  width: 18%;
  text-align: center;
}

.table-info {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
  box-shadow: none !important;
  background-color: #9c6ccf !important;
  color: white;
  font-size: 11px;
  border-radius: 0.5rem;
  padding: 1.3rem;
  margin-top: 1.3rem;
}
.text-editor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.text-editor input {
  font-family: "Courier New", Courier, monospace;
  font-size: 90% !important;
  text-align: center;
  border: 1px solid #cccccc;
  padding: 0.075rem;
  margin: 0px !important;
  letter-spacing: 3px;
  width: 18%;
}
.part-selected {
  background-color: #9c6ccf;
  border-radius: 10px;
  padding: 0.25rem;
  color: white;
}
.part-error {
  background-color: red;
  border-radius: 10px;
  padding: 0.25rem;
  color: white;
}
.part-info-error {
  color: red;
  font-weight: bold;
  font-style: italic;
}
.part-info-selected {
  color: #9c6ccf;
  font-weight: bold;
  font-style: italic;
}
.col {
  display: flex;
  flex-wrap: wrap;
}
.next-date {
  font-size: 12px;
}
.tablestyles-header {
  border-bottom: 1px solid #d1cdcd;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  padding: 2px;
}
.tablestyles-footer {
  border-top: 1px solid #d1cdcd;
  padding: 19px 0 20px;
  margin-top: 20px;
  text-align: right;
}

.tablestyles-header > h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
}

.breadcrumb-custom {
  padding: 15px 0px 14px 31px !important;
  box-shadow: 0px 0px 10px 0px #c9c8c6;;
}

.tablestyles-body {
  padding: 0.3rem;
}

