/** START CSS **/

html,button,input,select,textarea {color: #222; }
body {font-size: 1em; line-height: 1.4;}

::-moz-selection {background: #b3d4fc; text-shadow: none;}
::selection {background: #b3d4fc; text-shadow: none;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}
img {vertical-align: middle; display: block;}
fieldset {border: 0; margin: 0; padding: 0;}
textarea {resize: vertical;}

/* HELPERS */

.ir {background-color: transparent; border: 0; overflow: hidden; /* IE 6/7 fallback */*text-indent: -9999px;}
.ir:before {content: ""; display: block; width: 0; height: 150%;}

.hidden {display: none !important; visibility: hidden;}
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto;}

.invisible {visibility: hidden;}
.iblock {display: inline-block;}

.bold {font-weight: bold}
.left { float: left;}
.right { float: right;}

.margin0 {margin: 0;}
.right5 {margin-right: 5px}
.top5 {margin-top: 5px}
.top10 {margin-top: 10px}
.top15 {margin-top: 15px}
.bot0 {margin-bottom: 0 !important}
.bot5 {margin-bottom: 10px !important}
.bot10 {margin-bottom: 10px}
.bot20 {margin-bottom: 20px}
.bot30 {margin-bottom: 30px}
.bot40 {margin-bottom: 40px}
.padtop25 {padding-top: 25px;}

#maincontent .bot0 {margin-bottom: 0}

.right0 { margin-right: 0}
.right2 { margin-right: 2%}
.right10 {margin-right: 10px;}
.right20 {margin-right: 20px}
.left10 {margin-left: 10px;}

.color, a {color: $textColor;}
a[href^="tel:"] {color: $textColor;}

.nopadding {padding: 0;}
.nomargin {margin: 0;}

.positionr {position: relative;}

/* CLEARING */
.clear { clear: both;}
.clearright {clear: right;}
.group:before,
.group:after {
    content:"";
    display:table;
}
.group:after {
    clear:both;
}
.group {
    zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.popit{
    cursor:pointer;
}
.popover-title{
    font-weight:bold;
    font-size:16px !important;
    color:#54248a;
}
.popover-content{
    font-size:15px;
    line-height:16px;
    font-weight:normal;
}

.statWrapper {
  display: flex;
  flex-wrap: wrap;
}

/* DEFAULTS */

body {background: url(/images/texture01.jpg); font-family: 'Roboto', sans-serif; -webkit-font-smoothing: antialiased;}

  header, footer {
    padding: 10px 10px;
    background: url(/images/texture01.jpg);
    position: relative;
    z-index: 20;

    &.dev{
        background: #6b4343;
    }
    &.staging{
        background: #685c41;
    }
  }
    header .container {margin: 0 auto;}
    .logo {
        position: relative;
        width:176px;
        height:37px;
        float: left;
        background-size: 176px 96px;

        span{
          position: absolute;
          left: 19px;
          color: #fff;
          top:24px;
          font-weight: bold;
        }
    }
    .adminmode {
      padding: 3px 5px;
      height: 27px;
      width: 27px;
      display: inline-block;
      background: $grayReg;
      color: $grayLightest;
      @include border-radius(50px);
      @extend .boxsize;
      margin-top: 4px;
      text-align: center;
      line-height: 21px;
      float: left;
      &:hover{background: $grayLight;}
    }

    #search {
      @extend .animate2;
      background: #1f1f1f;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      box-shadow: 0 1px 0 #696969;
      float: right;
      padding: 5px;
      margin: 0 15px 0 0;
      input {
        height: 23px;
        line-height: 23px;
        font-size: 13px;
        margin-bottom: 0;
        border: none;
        text-align: left;
        padding: 0;
        color: #cccccc;
        background: none;
        float: left;
        margin: 0 5px 0 0;
        width: 190px;
      }
      button{
        &.searchBtn {
          width: 20px;
          height: 20px;
          background: none;
          border: none;
          color: #a9a9a9;
          padding: 0
        }
        &.btn {
          padding: 3px 6px;
          letter-spacing: 0;
          line-height: 12px;
          margin-top: 0;
          float: right;
          background: #333;
          border: 1px solid #000;
          color: #ccc;
        }
        &.btn .caret {
          margin-top: 4px
        }
      }
      .closeBtn {color: #cccccc; @extend .hide;}
    }


    .signoff {
      width: 19px;
      height: 19px;
      margin-top: 5px;
      img {width:100%; height: auto;}
    }

    .todos { height: 19px; margin: 5px 12px 0 0; min-width: 39px}
        .taskicon {margin-right: 5px; color: #ffffff;}

    .profile { height: 19px; margin: 5px 0 0 0;}
        a.profileicons { font-size: 16px; height: 100%; width: 39px; color: #ffffff;}
        .userNav li a{padding:5px 14px !important;}
        .userNav li.divider{padding:0 !important; margin:3px 0 !important;}

    .dashnavbtns {height:19px;width:14px;margin-right:15px;}

    .navactions { min-width: 120px}

    .signal{ color:#cdcdcd; font-size:0.9em; margin:7px 0 0 10px;}
    .signal.red{color:#ff4a4a;}
    .signal.green{color:#53d798;}
    .signal.yellow{color:#ffdf2d;}


    #tasktable td { text-align: left; padding-right: 30px}
    #tasktable .alertclose {right: 10px; line-height: 25px; text-align: center;}
    input.fullwidth {padding: 4px 3% !important; width: 100%; display: block;}

#loginarea {
        width: 260px;
        height: 370px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -130px;
        margin-top: -180px;
        padding: 20px 0 60px;
    }
    #loginarea .logo { float: none; display: block; width: 258px; height: 71px; margin: 0 auto; margin-bottom: 20px}
    #loginbox .loginwrap {border: 1px solid #000000; border-radius: 4px; -webkit-border-radius: 3px;}
    #loginbox .title { padding: 10px; font-size: 14px; font-weight: bold; text-transform: uppercase; color: #ffffff; text-align: center; background: #000000; border-radius: 3px 3px 0 0; -webkit-border-radius: 3px 3px 0 0;}
    #loginbox .loginform {
        background: #e7e4df;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(20%,#ffffff), color-stop(60%,#e7e4df));
        background: -webkit-linear-gradient(top,  #ffffff 20%,#e7e4df 60%);
        background: linear-gradient(to bottom,  #ffffff 20%,#e7e4df 60%);
        padding: 15px;
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 0;
        border-radius: 0 0 3px 3px;
        -webkit-border-radius: 0 0 3px 3px;
        -moz-box-shadow: inset 0 -4px 0 0 #fff;
        -webkit-box-shadow: inset 0 -4px 0 0 #fff;
        box-shadow: inset 0 -4px 0 0 #fff;
    }
    #loginbox form { margin-bottom: 0;}
    #loginbox .loginform .alert {font-size: 12px;}
    #loginbox .loginform .alert span {font-weight: 700}
    .alert .close {
        position: absolute;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        right: 0;
        text-shadow: 0 1px 0 #ffffff;
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        opacity: 0.4;
    }

#mainarea, #orderdetails {
    background: #e7e4df;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%,#ffffff), color-stop(60%,#e7e4df));
    background: -webkit-linear-gradient(top,  #ffffff 20%,#e7e4df 60%);
    background: linear-gradient(to bottom,  #ffffff 20%,#e7e4df 60%);
    min-height: 1300px;
    position: relative;
    overflow: hidden;
    }

    #menu {
        box-shadow: 0 0 10px #a5a5a5;
        -webkit-box-shadow: 0 0 10px #a5a5a5;
        -moz-box-shadow: 0 0 10px #a5a5a5;
        z-index: 10;
        position: absolute;
        background: $primaryNavBackground;
        width: 230px;
        left: 0;
        top:0;
        bottom:0;
        float: left;
    }

        #navheader {
            border: 1px #9f9f9f solid;
            border-right: none;
            border-left: none;
            padding: 10px 30px;
            z-index: 10;
            position: relative;
            background: #ffffff;
            background: -moz-linear-gradient(top,  #ffffff 0%, #e8e8e8 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e8e8e8));
            background: -webkit-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
            background: -o-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
            background: -ms-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
            background: linear-gradient(to bottom,  #ffffff 0%,#e8e8e8 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8e8e8',GradientType=0 );
        }
            #navheader span { color: #000000; font-size: 22px; float: left; line-height: 30px;}
            .saveall{}
            .collapsebtn {height:30px; width:30px; border-radius: 15px; border: 1px solid #dddddd; position: absolute; cursor: pointer; right: 6px; top: 10px; background: #ffffff; z-index: 10; text-align: center; line-height: 34px; color: $textColor;}
            .expandbtn {height:34px; width:25px; position: absolute; right: 0; cursor: pointer; top: 9px; background: #1799bd; border-radius: 0 3px 3px 0; color: #ffffff; text-align: center; line-height: 38px}

        nav ul.navone {
          //margin: 0; padding: 20px 20px 0 30px;
            li {position:relative;}
            li a { background-color: $primaryNavColor; color: #ffffff; text-decoration: none; display: block; padding: 8px 10px; margin-bottom: 5px; font-size: 13px; font-weight: bold; font-size: 13px}
            li a:hover, li.active a {background-color: $primaryNavHighlight;}
            li a span { float: right;}
            li:last-child{display:none;}
            li a.assigned{background:$primaryNavPersonal;}

            li.active:after {
                content: '';
                position: absolute;
                top: 0px;
                right: -23px;
                width: 0;
                height: 0;
                border: 17px solid transparent;
                border-left: 6px solid $primaryNavColor;
                transition: border-left 0.2s;
            }

            li.active:hover:after, li.active:after{
                border-left: 6px solid $primaryNavHighlight;
            }

            li.active.mine:after, li.active.mine:hover:after{
                border-left: 6px solid $primaryNavPersonal;
            }

        }
        .navone.fold{
            li{display:none;}
            li:last-child{display:block;}
        }
        .navone, .navtwo {
          padding: 0;
          margin: 0;
        }

        nav ul.navtwo {
          //margin: 0; padding: 20px 20px 0 30px;
        }
        nav ul.navtwo li { border-top: 1px solid #8a8a8a; background: #f6f2e9;}
        nav ul.navtwo li:nth-child(even) {background:#fdfaf4;}
        nav ul.navtwo li:last-child {border-bottom: 1px solid #8a8a8a;}
        nav ul.navtwo li a {color: #525252;padding: 10px; display: block;}
        nav ul.navtwo li a:hover {background: #ffffff;}

        nav a.newhipchat, nav a.newhipchat:hover {color: #ffffff; font-size: 14px}

        .subnav {
          //padding: 20px 20px 0 30px;
        }
        .subnav .header { color: #ffffff; background: #666666; padding: 10px; text-transform: uppercase; font-size: 12px; font-weight: bold; border-bottom:1px solid #3f3f3f;border-top:1px solid #898989; cursor:pointer;}
        .subnav ul { padding-left: 0}
        .subnav ul li {font-size: 13px; line-height: 16px; padding: 5px 15px 5px 5px;}
        .subnav .statusicon { margin: 0 5px 0 0; float: left;}
        .subnav a {color: #231f20; display: block;}
        .subnav a:hover {color: $textColor;}

        .hipchat{
            margin-bottom:25px;
            small{
                color:#cdcdcd;
                font-size:.7em;
                width:100%;
                text-align:center;
                display:block;
                margin-top:14px;
            }
        }

        .settingsNav, .invoicingNav{
            margin:0;
            padding:13px 0 0 20px;
            border-top:1px solid #9f9f9f;
            border-left:1px solid #9f9f9f;
            border-right:1px solid #9f9f9f;
            background:#f4f4f4;

            span.badge{
                font-size:12px;
                margin:0 0 0 6px;
            }
        }

        ul.customernotes, ul.opcustomernotes {
            padding-left: 0;
            margin-top: 0;
            .icon-check {
                color: $purpleReg;
                font-size: inherit;
            }
        }

    #mainarea {padding: 0 0 100px 230px;}
    #maincontent { width: auto; z-index: 1; position: relative;}
        #contentTop { background-color: #ffffff; padding: 20px 3% 0; position: relative;}
            #contentTop h1 {
              color: #000000;
              font-size: 22px;
              float: left;
              line-height: 30px;
              margin: 0;
              position: relative;
              margin-bottom: 30px;
              a {line-height: 35px; height: 30px; }
              small.custstatus {font-size: 12px; color: $grayLight; position: absolute; bottom: -10px; left: 50px; line-height: 12px}
            }

            .textright { float: right; line-height: 30px; margin-right: 0; font-weight: bold; font-size: 22px}

            #maincontent .roundbtn, .modal .roundbtn {
              border-radius: 20px;
              font-weight: bold;
              font-size: 16px;
              padding: 5px;
              color: #ffffff;
              height: 22px;
              width: 22px;
              line-height: 22px;
              text-align: center;
            }
            #maincontent .roundbtn img, .modal .roundbtn img {margin: 1px auto 0;}
            #contentTop .headeraction {position: absolute; right: 30px; line-height: 30px; font-weight: bold; color: #525252;}
            #contentTop .headeraction div.roundbtn{margin:0 10px 0 5px;position:relative;top:-2px;}
            #contentTop .orderActions {
              float: right;
              a, .directConnect {margin: 0 0 20px 10px}
            }
            #contentTop .headeraction:hover {color: $textColor !important;}
            #maincontent .expandorder {font-size: 12px; line-height: 14px;padding:5px 6px;position:absolute;bottom:20px;right:0px;border-radius:5px;}
            #maincontent .popLock{
              float:left;
              height:34px;
              width:34px;
              font-size:.9em;
              &.restricted{
                color: #ffffff;
              }
            }
            #contentTop {
              a.show_design_status {
                margin-bottom: 0;
              }
              .dropdown-menu.design_status>li>a {
                line-height: normal;
                margin: auto;
              }
            }

        #orderdetails .container {max-width: 1200px; margin: 0 auto; position: relative;}
        #orderdetails #contentTop .headeraction {position: relative; right: 0;}
        #orderdetails h1 {line-height: 38px}
        #orderdetails h1 span {display: inline-block;}
        #orderInfo {
            padding: 15px 3%;
            color: $grayLightest;
            strong {color: #ffffff; display: inline-block;}
        }
        #orderInfo .container {max-width: 1175px; padding-right: 25px; font-size: 18px}
        #orderInfo span { padding-right: 35px; float: left; }
        #orderInfo .info {width: 20px; height: 20px; background: url(/images/info.png) top left no-repeat; float: right; position: absolute; right: 0; cursor: pointer;}
            .detailsTop {position: relative;}
            .expanddown {background: url(/images/expanddown.png) center center no-repeat; width: 22px; height: 22px; display: block;}
            .expandup {background: url(/images/expandup.png) center center no-repeat; width: 22px; height: 22px; display: block;}
            .update {width: 29px; height: 26px; cursor: pointer; margin-bottom: -4px; background: url(/images/update.png) top left no-repeat; display: inline-block; /*-webkit-animation-name: spaceboots; -webkit-animation-duration: 0.8s; -webkit-transform-origin:50% 50%; -webkit-animation-iteration-count: infinite; -webkit-animation-timing-function: linear;*/ }
            .detailsLeft {font-size: 14px}
            .detailsLeft h2 {margin-bottom: 0; line-height: 17px; font-size: 15px; margin-bottom: 10px}
            .detailsLeft h2 .small {font-size: 12px; font-weight: normal; display: inline-block; font-style: italic;}
            .detailsLeft h2 .price_small {font-size: 18px; font-weight: normal; display: inline-block;}
            .detailsLeft h2 .label {margin:0 10px 0 0;}
            .detailsLeft h2 .jName {color: #000000;}
            .statDiscription {font-weight: bold; font-size: 18px;}

            .jobspecs ul{padding-left: 0; font-size: 14px; margin: 10px 0 20px 0}
            .jobspecs ul li {margin-left: 0; list-style: none}
            .jobspecs span {color: #a3a3a3; font-size: 12px}

            .eddmBlock{
                float:right;
                padding:5px 10px;
                margin-top:8px;
                border: 1px solid #cdcdcd;
                border-radius: 6px;
                h4{
                    position: relative;
                    top: 2px;
                    margin-bottom:6px;
                }
                overflow: hidden;
                div{
                    float:left;
                    margin-bottom:6px;
                    width:25%;
                    &:nth-child(7){width:50%;}
                    span{
                        width:100%;
                        float:left;
                        clear:both;
                        font-weight: normal;
                    }
                    a{
                        position: relative;
                        top: 10px;
                    }
                }
            }

            .refstatus {
              .statuses, .referenceId {
                margin-bottom: 20px;
                select, button, input {margin-bottom: 0;}
              }
            }


            .detailsRight {
                font-size: 14px;
                padding-top: 20px;

                .date-info{
                    position: absolute;
                    margin-top: -31px;
                    display: inline-block;
                }
            }
            .job-type {position: absolute; top: 0; right: 0}
            .statBlindto{}
            .detailsRight span { font-weight: bold}
            .joblist {padding: 0; margin: 0;}
                .joblist li.job { list-style: none; padding: 20px 3% 5px 3%; background: #ffffff; position: relative;}
                .detailsRight a {color: $textColor;}
                .joblist li .roundbtn {position: absolute; right: 0; bottom: 20px}
                .joblist li.job:nth-child(even) {background: #efefef; border-top: 1px solid #c9c8c6;}

        .couponContainer{margin-right:-25px !important;position:relative;}
        input.fourcoupon{margin:0 !important;height:28px !important;background:#582791 !important;border:1px solid #8a5ebc !important;color:#a783d1 !important;}

        .csractionsRight {
          float: right;
          .closeBtn, .expandall, .closeall, .saveall, .sendProofs {
            margin-left: 10px
          }
          a {margin-bottom: 10px}
        }

        .csractionsLeft {float: left;}

        .statShipto{}

            div.statGroupShipping{display:none;}

            .job.subjob{
                border-left:8px solid #dddddd;

                div.statGroupShipping{display:block;}
                div.statDiscription{display:none;}
                div.statFacility{display:none;}
                div.statShipping{display:none;}
                div.statShipto{display:none;}

                div.detailsRight{
                    margin-bottom:0 !important;
                }

            }
            .job.job_attention{
                border-left:6px solid #ffe100;
            }
            span.job_attention{
                position:relative;
            }
            span.job_attention:before{
                content:"";
                width:11px;
                height:11px;
                background:#ffe100;
                border-radius: 10px;
                display:block;
                float:left;
                position:relative;
                top:5px;
                margin-right:5px;
            }

            .popUser { position:relative; top:-2px; }

            #orderHistory td { text-align: center}
            .modal #tasktable td, .modal #shorttable td {padding: 3px 10px;}
            .modal #tasktable td.left30 {padding-left: 30px}

            .modal-body.nomargin{
                padding:0;
            }

            .checkdelete {
                .btn-mini {color: #ffffff; font-weight: bold; padding: 5px 10px}
                .yesdelete {background: #e00000}
                .nodelete {background: #a3a2a2}
            }

            .keyvalrow {
                padding-right: 25px;
                position: relative;
                margin-bottom: 15px;
                input[type="text"] {
                    margin-bottom: 0;
                }
                .deleteData {
                    right: 0;
                    color: $grayLighter;
                    cursor: pointer;
                    @extend .vertical-align-ab;
                }
            }

            .leftSideModal{
                float:left;
                width:35%;

            }
            .leftSideModal h3{
                font-size:16px;
            }
            .rightSideModal{
                border:1px solid #dedede;
                background:#fafafa;
                border-radius:5px;
                font-size:12px;
                float:right;
                width:64%;
                min-height:250px;
                overflow-x:hidden;
            }

            .rightSideModal::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            .rightSideModal::-webkit-scrollbar:vertical {
                width: 8px;
            }

            .rightSideModal::-webkit-scrollbar:horizontal {
                height: 11px;
            }

            .rightSideModal::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, .3);
            }
            .rightSideModal::-webkit-scrollbar-track { background-color: #dedede; }

            .topStats{
                margin:4px 0;
                li{
                    margin:0 !important;padding-left:20px;box-sizing: border-box;
                    p{margin:0;color:#bcbcbc;}
                }
            }

            .editComments {display: inline-block;}
            .editComments img {margin-bottom: -2px}
            #editComments textarea { margin: 20px 0 10px; height: 100px;}

            .messagePanel {width: 49%; margin-bottom: 30px}

                .messagePanel span.status{float:right;margin-top:0px;margin-left:10px;}
                .messagePanel span.green{color:#10bd10;}
                .messagePanel span.blue{color:#3e88ab;}
                .messagePanel span.gray{color:#dedede;}

                .accordion{
                    margin-bottom:0 !important;
                }

                .accordion-body{
                    background:#f8f8f8;
                }
                .accordion-toggle span.label{
                    position:relative;
                    top:1px;
                    margin-right:3px;
                    background:#eee;
                    border:1px solid #ddd;
                    color:$primaryColor;
                    text-shadow: none;

                }

            .tripple {width: 32%;}
            .tripcenter{margin-left:2%;}

            .addressBlock{
                float:left;clear:both;
            }
                .addressBlock div.group{position:relative;}
                .addressBlock h4{position:absolute;}
                .addressBlock span{margin:10px 5px 0 0;}
                .addressBlock div.group div.left{
                    margin:-25px 0 0 10px;
                }

            td.highlight{background:#aaf7ab;}

            .desk, .intercoms{background:#fff;}
                .desk ul.nav, .intercoms ul.nav{
                    margin:0 0 0 0;
                    padding:12px 0 0 12px;
                    background:#eee;
                }
                .desk a.newDesk, .desk a.refreshDesk, .intercoms a.newIntercom, .intercoms a.refreshIntercom{margin:13px 12px 0 0;}
                .deskText{
                    margin-top:10px;
                    display:none;
                }
                .sendDesk, .relateDesk{
                    margin-bottom:10px;
                }
                .deskRight, .intercomRight, .customerIntercomRight {background: #eee; float: right;}
                .deskRight {@extend .boxsize;}

            .qaicon { width: 100%; height: 24px; text-align: center; line-height: 24px; font-weight: bold; font-size: 14px; background: #ffffff; border: 1px solid #c9c8c6; border-radius: 3px; margin-bottom: 15px}

            .jobActions {
                position: absolute;
                display: none;
                width: 24px;
                /*height: 49px;*/
                left: -25px;
                background: #ffffff;
                border: 1px solid #c9c8c6;
                border-radius: 3px 0 0 3px;
            }

                .jobActions.short {
                    height:30px;
                }

                .jobActions a {
                    color: #ffffff;
                    margin: 5px 0 0;
                    left: 4px;
                    height: 17px;
                    line-height: 17px;
                    width: 17px;
                    display: inline-block;
                    position: relative;
                    overflow: hidden;
                }

                /*.jobActions .jobdelete {left:4px; top: 5px; height: 17px;}
                .jobActions .edit {left:4px; top: 27px; height: 17px; line-height: 17px; min-height: 17px; position: absolute;}*/

                .edit {width: 17px; height: 100%; min-height: 17px; top: 0; right: 20px; position: absolute; cursor: pointer; overflow: hidden}
                .jobdelete {width: 17px; height: 100%; min-height: 17px; top: 0; right: 20px; position: absolute; cursor: pointer; overflow: hidden}

            .creditAmmount {display: inline-block; min-width: 80px}

            .orderDrop {
              border-top: 1px solid #cccccc;
              padding: 20px 0 0 0;
              display: none;
              .btn.addit {
                background-color: $primaryColor;
                color: #ffffff;
                border: 0;
                @extend .boxsize;
              }
            }

            .orderprice {
              text-align: right !important;
              font-size: 18px;
              span {float: left;}
              .price {width: 100px;}
              .color {font-weight: bold; color: #3e88ab}
            }


            .oldprice {color: #cccccc; position: relative;}
            .oldprice:before {
              position: absolute;
              content: "";
              left: 0;
              top: 50%;
              right: 0;
              border-top: 2px solid;
              border-color: #bd372e;
              -webkit-transform:rotate(-10deg);
              -moz-transform:rotate(-10deg);
              -ms-transform:rotate(-10deg);
              -o-transform:rotate(-10deg);
              transform:rotate(-10deg);
            }
            .couponused {font-size: 10px; line-height: 11px; display: inline-block; color: #cccccc;}

            .insertText{margin-top:-21px;}

        tr.first td a.mup{display:none;}
        tr.last td a.mdown{display:none;}

        #sitewideAlert, .orderModification, #outstandingCharges{
            padding: 10px 30px;
            line-height:28px;
            position: relative;
            background: $caution;
            background-size: auto 100%;
            color: #000000;
            a {color: #000000; line-height: 27px; background: url(/images/sitewidealert.png) left 3px no-repeat; padding-left: 30px; padding-right: 37px; float: right;}
            a:hover {color: $primaryNavHighlight;}
            .ordermodTitle {font-weight: bold}
            &.active{
                -webkit-animation: progress-bar-stripes 2s linear infinite;
                -moz-animation: progress-bar-stripes 2s linear infinite;
                -ms-animation: progress-bar-stripes 2s linear infinite;
                -o-animation: progress-bar-stripes 2s linear infinite;
                animation: progress-bar-stripes 2s linear infinite;
            }
        }

        // #sitewideAlert2, #customerComments {
        //     padding: 10px;
        //     line-height:28px;
        //     position: relative;
        //     background: #ffffff;
        //     max-width: 1200px;
        //     margin: 0 auto 10px auto;
        //     @extend .boxsize;
        //     @include border-radius(3px);
        //     border: 1px solid #c3c3c3;
        //     color: #ad0709;
        //     span {color: #000000;}
        //     .ordermodTitle {font-weight: bold}
        // }

        .orderAlerts .alertBox {
          padding: 10px;
          line-height:28px;
          position: relative;
          background: #ffffff;
          max-width: 1200px;
          margin: 0 auto 15px auto;
          @extend .boxsize;
          @include border-radius(3px);
          border: 1px solid #c3c3c3;
          color: #000000;
          & strong {color: #ad0709}
          .ordermodTitle {font-weight: bold}
          &:last-child {margin-bottom: 0}
        }

            .alertclose, .addressdelete {width: 17px; height: 100%; line-height: 25px; text-align: center; min-height: 17px; top: 0; right: 20px; position: absolute; cursor: pointer; font-family: 'primoadmin'; opacity: .5; /*background: url(/images/alertclose.png) center center no-repeat;*/}

            .alertclose:before, .addressdelete:before {content: "\21";}

            .alert.locked-alert{
                a{color:#C09853;text-decoration:underline;}
                .close {top: 10px; right: 10px}
            }

            .addressdelete {right: 5px;}

            .addressedit {width: 17px; height: 22px; display: inline-block; cursor: pointer; top: 0; right: 30px; position: absolute; height: 100%; min-height: 17px; font-family: 'primoadmin'; opacity: .5; text-align: center; line-height: 25px;}
            .addressedit:before {content: "\24";}

            .confirmaddress {width: 17px; height: 100%; min-height: 17px; top: 0; right: 26px; position: absolute; cursor: pointer; font-family: 'primoadmin'; opacity: .5; text-align: center; line-height: 25px;}
            .confirmaddress:before {content: "\43";}

            h4.addresstitle{color:$primaryNavColor;}
            .selectAddress{display:none;}
            .addressSelection{
                float:left;
                width:100%;
                text-align:left;
                font-size:.9em;
                margin-bottom:20px;
                padding:6px 10px;
                box-sizing: border-box;
                border-radius:6px;
                border:1px solid #dedede;
                background:#fff;
            }
            .addressSelection:hover{
                background:#fefff2;
                cursor:pointer;
                box-shadow: 0 0 5px #bcbcbc;
            }
            .addressSelection h5{
                font-size:1em;
                margin:0 0 5px 0;
                padding:0 0 5px 0;
                border-bottom:1px solid #ccc;
            }
            .addyNotice{margin:10px 0 5px 0;color:#900;}

            .addbtn, .removebtn  {width: 19px; height: 100%; min-height: 19px; top: 0; right: 5px; position: absolute; cursor: pointer; font-family: 'primoadmin'; opacity: .5; text-align: center; line-height: 25px}
            .addbtn:before {content: "\23";}
            .removebtn:before {content: "\22";}

        #modal, #confirm {
            position: fixed; max-height: 80%; overflow-y: auto;
        }

        .checkfloat {
          padding-left: 20px;
          position: relative;
          @extend .boxsize;
          input {
            position: absolute;
            left: 0;
          }
        }

        #qaticket .jobs {
          border: 1px solid $grayLightest;
          border-radius: 3px;
          padding: 15px 10px 0 10px;
        }

        .notice {color: #BD362F;}

        .footable.pricingtable > tbody > tr > td, .footable.pricingtable > tbody > tr > th {
            padding: 5px 0;
        }

        .jlist tbody tr td:nth-child(3){text-align:left;}
        .jlist tbody tr td:last-child{text-align:right;}

        .methodtitle{float: left;margin:2px 0 0 0;}
        .paymentMethods{margin:0; padding:0; float:right;}
        .paymentMethods label {padding: 0;}
        .paymentMethods li {float: left; margin: 0 0 0 5px; line-height: 32px; width:68px; height:32px; list-style: none;margin-left:20px;}
        .paymentMethods input[type="radio"] + label span { margin: 0 5px 0 0}
        .method {width: 46px; height: 32px; overflow:hidden; background: url(/images/paymentmethods.gif) top left no-repeat; cursor: pointer;}
            .method.credit {background-position: 0 0;}
            .method.echeck {background-position: 0 -32px;}
            .method.paypal {background-position: 0 -64px;}
            .method.invoice {background-position: 0 -96px;}

            .method.visa {background-position: 0 -128px;}
            .method.mastercard {background-position: 0 -160px;}
            .method.discover {background-position: 0 -192px;}
            .method.amex {background-position: 0 -224px;}

        .checkimage {text-align: center}
        .checkimage img{width: 100%; height: auto; max-width: 558px; margin: 0 auto;}

        .savemethod {padding: 38px 0 0 25px; position: relative;}
        .savebank {padding: 0 0 0 25px;}
            .savemethod a { margin-left: 5px; color: #cccccc !important;}

        .paypalpayment img {width: 100%; max-width: 468px; height: auto;}
            .paypalpayment span {color: $blueReg;}
            .errorList{
                border:1px solid #ffe2e2; background-color:#ffefef; border-radius:6px; padding:5px 5px 3px; margin-bottom:6px;
                p {font-size:1em !important; color:#900; padding:5px; margin:0;}
            }



        #customer {
            margin-bottom: 20px;

            hr{
                width:100% !important;
                height:1px !important;
                background:#dedede !important;
                clear:both;
                margin:10px 0;
            }

            a.btn-mini{position: relative; top: -1px;}

            .tagEm{
                display: none;
                position: relative;
                button{
                    position: absolute;
                    top: 7px;
                    right: 5px;
                }
            }
        }
            #customer h2 {margin-bottom: 0}
            .editaccount {margin: 6px 0 0 10px;}
            #customer .customerpic { margin: 0 15px 10px 0; max-width: 90px}
            .statustext {line-height: 16px; font-size: 14px; width: 100%}
            #customer .statusicon { margin-right: 5px}
            .customerinfo{
                overflow: hidden;
                max-width: 405px;
                .circleBtns{
                    position: absolute;
                    right:15px;
                }
                h3{margin-bottom:0;}
                .badge{
                    background: #bbb;
                    display:block;float:left;
                }
            }

        .recentUsers{
            img{margin:8px 0 0 0;}
            th, td{text-align:left !important;padding-left:8px !important;}
            td{height:30px !important;line-height:46px;padding:0 0 0 8px !important;}
        }

        .user_card{
        	border-radius:5px;
        	border:1px solid #cdcdcd;
        	padding:10px;
        	box-sizing:border-box;
        	margin:0 1% 1% 0;
        	position:relative;

        	&:hover{
        		background:#efefef;
        	}

        	a.removeEstimate{
        		position:absolute;
        		top:50%;
        		margin-top:-10px;
        		right:10px;
        		font-size:13px;
        	}

        	img{
        		float:left;margin-right:10px;
        	}
        }

        #content .container {padding: 20px 48px 30px 3%;}

        .statusicon {width: 16px; height: 16px; display: inline-block; margin: 0 auto; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; }
            .statusicon.red { background: #bb4242;}
            .statusicon.blue { background: #74c2d7;}
            .statusicon.green { background: #4bbb42;}
            .statusicon.yellow { background: #f4c900;}
            .statusicon.gray { background: #bbb;}
            .icon.lock{display:inline;cursor:pointer;color:$primaryColor;font-size:1.1em;margin-left:4px;position:relative;top:-1px;}
            .icon.lock.green{color:#090;}

        .tablestyles {
          color: #525252;
          padding: 25px;
          box-shadow: 1px 1px 10px 1px #c9c8c6;
          flex-direction: column;
          background-color: #fff;
          border: 1px solid #eff2f5;
          border-radius: 0.625rem;
          margin-bottom: 1.5rem;
        }
            .tablestyles h3 {font-weight: normal}
            .tablestyles h3 span { color: $textColor; font-weight: bold}
            .tablestyles table { background: #ffffff; margin-bottom: 25px; box-shadow: 0 2px 2px #c9c8c6}
            .ordernumber a {color: $textColor; font-weight: bold}
            .tablestyles tfoot, .tablebot {
                background: #ffffff;
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e9e9e9));
                background: -webkit-linear-gradient(top,  #ffffff 0%,#e9e9e9 100%);
                background: linear-gradient(to bottom,  #ffffff 0%,#e9e9e9 100%);
                padding: 10px;
                border-top: 1px solid #ccc;
            }

            .fileGroup{margin-bottom:30px;}
            .uploadedFiles { margin: 0; padding: 0;}
            .uploadedFiles li {list-style: none; padding: 5px; background: #ffffff;}
            .uploadedFiles li .filetype {min-width: 25%; display: inline-block; font-weight: bold;}
            .uploadedFiles li .filename { display: inline-block; position: relative; padding-left: 27px;}
            .uploadedFiles li .filename .statusicon { position: absolute; left: 0; top: 2px;}
            .uploadedFiles li:nth-child(even) {background: #efefef;}
            .uploadedFiles .alertclose { position: relative; display: block; height: 22px; float: left; right: 0; margin-right: 10px}

            .paymentleft {float: left; width: 50%; font-size: 18px; font-weight: bold; margin-bottom: 15px}
            .paymentright {float: right; width: 50%; font-size: 18px; font-weight: bold; text-align: right}
            .paymentright span { width: 100px; display: inline-block;}

            .paymentTotals {padding: 20px 3%;}
            .modifications{
                margin:0;padding:0;position:relative;overflow:hidden;

                p{
                    position:absolute;
                    display:none;
                    right:30px;
                    top:-6px;
                    width:345px;

                    button{
                        float:right;
                        margin-left:10px;
                        clear:none;
                        display:inline-block;
                    }
                }
            }

            .messageAdd{
                background:#efefef !important;border-bottom:1px solid #dedede !important;position:relative;padding-bottom:0 !important;
                textarea{width:100%;}
                a.filterNotes{width:2.5%;position:absolute;top:20px;right:2%;height:30px;line-height:32px;}
                ul{
                    margin-bottom:0; border-bottom:0;padding-left:0;
                }
            }
            .intNote{margin:0;}
            .postInternal{margin-top:20px;}
            .messages {padding: 0;}
            .messages p img{max-width:160px;}
            .messages pre {
              font-size: 14px;
              padding:0 7px;
              font-family: arial;
              background:#fff;
              border:1px solid #efefef;
              border-radius:4px;
              margin-top:0;
              margin-bottom:0;
            }
            .intercom div.accordion-heading{padding-right:0px !important;}
            a.accordion-toggle{
                padding:7px 7px 8px 14px !important;
                small{
                    color:#ccc;
                    font-weight: bold;
                }
                span.label{line-height:14px;}
            }
            .sendIntercom{float:right;margin-left:10px;clear:none;display:inline;}

            .messages div.singleMsg{margin-bottom:14px;background: #efefef;border:1px solid #dedede;border-radius:4px;}
            .messages div.bot, .messages p.bot{display:none;}
            div.singleMsg.primo{
                background:#f8f4fc;
            }
            .messages p { font-size: 14px; padding:0 0 7px 7px;}
            .messages h4{margin:0 0 1em;}
            .messages table { margin-bottom:14px;}
            .messages table:last-child { margin-bottom:0px;}
            .messagetitle {
                padding: 5px 5px 5px 7px; font-size: 14px; color: #333; border-bottom:1px solid #dedede;
                &.bot{color:#aaa;}
            } /* background: #efefef;border:1px solid #fff;border-radius:4px; */
            .messagetitle small{float:right;}
            .messagetitle.fullwidth{padding:5px 2px 5px 1px;}
            .messageCard {max-width: 60%; float: left;}

            .deskReply{
                border-radius:0 0 4px 4px !important;
                border:none !important;
                line-height:1em;
                padding: 10px 10px 30px 10px;
                p{margin:0;padding:0 7px 2px 7px;}
                textarea{margin:0 0 10px 0;}
            }
            .initialConvo{padding-top:14px !important;}
            .showAllNotes{
                padding:4px 0;border-radius:5px;margin:0;background:#efefef;width:100%;display:block;text-align:center;
                &:hover{background:#dedede;}
            }

        #jobAlerts td {text-align: center}
        #jobAlerts th {text-align: center}

        #pendingJobs, #customEstimates{ position:relative;}
            ul.templateList{
                width:100%;
                clear:both;
                display:block;
                overflow:hidden;
                margin:0;
                padding:0;

                li{
                    width:19%;
                    margin:0 1% 1% 0;
                    padding:0;
                    list-style:none;
                    float:left;
                    overflow:hidden;
                    .borderBottom{border-bottom:1px solid #eaeaea;padding:5px 0;margin: 0 0 5px 0;}
                    a{
                        display:block;
                        margin-bottom:1px;
                    }
                    img{
                        width:99.4%;
                        border-radius:3px;
                        border:1px solid #efefef;
                    }
                    .label{
                        margin-top:1px;
                    }
                }
            }
        #customEstimates .footable > tbody > tr > td {text-align: center}

        .optionGroups .header { color: #ffffff; background: #666666; padding: 10px; text-transform: uppercase; font-size: 12px; font-weight: bold; border-bottom:1px solid #3f3f3f;border-top:1px solid #898989; cursor:pointer;}
        .sectiondrop {padding: 10px 0 10px 2%}
        .optionGroups .header.dropsection {background: #000}
        .optionGroups .header.dropsubsection {background: #666666}
        .subsectiondrop table {margin: 10px 0 20px;}

        #widget { width: 14.28%; float: left; box-shadow: 0 2px 2px #c9c8c6}
            #widget .widgetwrap { border: 1px solid #c9c8c6; border-left:none; border-right:none;}
            #widget .title { padding: 10px; font-size: 13px; font-weight: bold; text-transform: uppercase; color: #ffffff; text-align: center; background: #000000;}
            #widget .title:hover {/*background: url(/images/widgetinfo.png) 95% center no-repeat #000*/}
            #widget .number {border-bottom: 1px solid #a1a1a1; color: #000000;}
            #widget .number, #widget .ammount { background: #ffffff; text-align: center; padding: 30px 10px; font-weight: bold; font-size: 21px}
             #widget .ammount { color: $textColor; padding: 10px; font-size: 14px}
                #widget .ammount ul {list-style: none; padding: 0; margin:0; text-align: center;}
                #widget .ammount li {position: relative; padding: 3px 3px}
                #widget .ammount ul span {color: #000000; position: absolute; text-align: center; color: #fff; left: 5px; background: #969696; border-radius: 20px; display: inline-block; font-size: 12px; line-height: 20px; width: 20px; height: 20px;}
                #widget .dayone ul span, #widget .daytwo ul span {display: none}
                #widget .ammount ul li:nth-child(even) { background: #e3e3e3}
                #widget .ammount ul span.green{background:#4bbb42;}
                #widget .ammount ul span.blue{background:#68daff;}
                #widget .ammount ul span.purple{background:#48047f;}

        #widget.balanceWidget{
            width: 33.33333%;
            .ammount{font-size:1.6em;}
        }

        #adminCat { width: 100%; display: block; box-shadow: 0 2px 2px #c9c8c6}
            #adminCat .catwrap { border: 1px solid #c9c8c6;}
            #adminCat .catwrap .wrap {padding: 20px 3%; background: #ffffff;}
            #adminCat .title {
              padding: 10px;
              font-size: 13px;
              font-weight: bold;
              text-transform: uppercase;
              color: #a1a1a1;
              text-align: center;
              background: #000000;
              position: relative;
              .seenotes {
                position: absolute;
                right: 10px;
                a{color: $caution;}
              }
            }

        .smallTable{
            width:100%;background:#EFEFEF;font-size:0.9em;
            /*border:1px solid #dedede;*/
            border-top:none;
            thead tr td{padding:6px 8px 2px 6px;border-bottom:1px solid #e9e9e9;}
            tbody tr td{padding:6px 8px 6px 8px;background:#f8f8f8;}
            tfoot tr td{padding:6px 8px;border-top:1px solid #e9e9e9;font-size:0.9em;}
        }

        .blockWrap { padding: 20px 2%; background: #ffffff;}

        .pagination {
            display: flex;
            gap: 15px;
            justify-content: center;
            list-style: none;
        }

        .pagination a {color: $textColor;}

        .headerRow{
            font-weight:bold;
            margin-bottom:14px;
            color:$secondaryColor;
        }

        .alts:nth-child(even){ background: #f9f9f9; }
        .alts{
            line-height:35px;
            padding:4px 0 4px 10px;
        }
            .alts label.control-label{height:35px;line-height:35px;}

        .direction a{
            color: #ffffff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
            background-color: #b190d2;
            background-image: -moz-linear-gradient(top, #b190d2, $primaryColor);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b190d2), to($primaryColor));
            background-image: -webkit-linear-gradient(top, #b190d2, $primaryColor);
            background-image: -o-linear-gradient(top, #b190d2, $primaryColor);
            background-image: linear-gradient(to bottom, #b190d2, $primaryColor);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
            border-color: #0044cc #0044cc #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            *background-color: #0044cc;
        }

        .widthhalf {width: 10%; margin-right: 1.2%;}
            .widthhalf.right0 {margin-right: 0}
        .widthone {width: 24%;}
        .widthonehalf {width:32.3%;}
            .widthonehalf.middleblock {margin: 0 1.5%;}
        .widthtwo {width: 49%;}
        .widththree {width: 74%;}
        .widthfour {width: 100%;}
        .widthquarter {width: 25%; padding-right: 10px; @extend .boxsize;}
        .widthquarter:last-child {padding-right: 0}
        .widthfifth {width: 20%; padding-right: 10px; @extend .boxsize;}
        .widthfifth:last-child {padding-right: 0}
        .widthsixth {width: 16.66%; padding-right: 10px; @extend .boxsize;}
        .widthsixth:last-child {padding-right: 0}

        .widthseven {width:13%; margin-right:1.25%; float:left;}

        /* LONG FORMS */
        .longform{
            .controls .control-group{float:left;}
            .widthone{width:23.25%;margin-right:2.25%;}
            .widthonehalf{width:31.75%;margin-right:2.25%;}
            .widthtwo{width:48.75%;margin-right:2.25%;}

            .controls .control-group:last-child{ margin-right:0; }

            h3{color:$textColor; border-bottom:1px solid #eee;padding:10px 0;font-size:1.1em;}

            .imgupload{border:1px solid #CCCCCC;padding:10px;box-sizing:border-box;border-radius:6px;margin-bottom:20px;}
        }

        #widgets {position: relative;}
        .reload {
            height:30px;
            width:30px;
            border-radius: 15px;
            border: 1px solid #dddddd;
            cursor: pointer;
            background: #ffffff;
            position: absolute;
            top: 20px;
            right: 26px;
            overflow: hidden;
        }

        .reload:before {
            font-family: 'primoadmin';
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 31px;
            z-index: 1;
            color: $textColor;
            font-size: 22px;
            content: "\42";
        }

        .reload:hover:before {
            -webkit-animation: spinX .5s;
        }

        @-webkit-keyframes spinX {
            0%   {-webkit-transform: rotate(360deg); -webkit-transform-origin: 50%;}
            100% {-webkit-transform: rotate(0deg); -webkit-transform-origin: 50%;}
        }

        @-webkit-keyframes spaceboots {
            0% { -webkit-transform: translate(0px, 1px) rotate(0deg); }
            10% { -webkit-transform: translate(0px, -2px) rotate(-1deg); }
            20% { -webkit-transform: translate(1px, 0px) rotate(1deg); }
            30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
            40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
            50% { -webkit-transform: translate(0px, 2px) rotate(-1deg); }
            60% { -webkit-transform: translate(1px, 1px) rotate(0deg); }
            70% { -webkit-transform: translate(0px, 1px) rotate(-1deg); }
            80% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
            90% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
            100% { -webkit-transform: translate(0px, -2px) rotate(-1deg); }
        }

        .datepicker{float:right;margin:0;padding:0;height:30px;position:relative;top:3px;}

            .datepicker a{
                float:right;
                margin:-3px 2px 0 10px;
                height:16px;
                width:21px;
                line-height:16px;
                border-radius:4px;
                color:#fff !important;
            }

            .datepicker a span{
                font-size:16px;
                color:#fff !important;
            }

            .datepicker input{
                float:right;
                width:150px;
                margin-left:10px;
                margin-top:-3px;
                height:30px !important;
            }

        .multiselect-container{width:800px;}
        .multiselect-container li{width:200px;float:left;}
        .multiselect-container li a{padding:8px 10px 1px 10px !important;border-radius:5px;}
        #primo-custom-multiselect .multiselect-container {width: 400px !important;}
        #primo-custom-multiselect .multiselect-container li {width: 400px !important;float:none !important;}
        #primo-custom-multiselect .multiselect-container .input-group {padding: 5px !important;}

        .charts{
            position:relative;
        }

        .stat{}
            .stat h2{
                font-size:1em;
                margin-bottom:none !important;
                color:#aaa;
            }
            .stat span{
                font-size:3em;
                color:#444;
            }
            #mycanvas{
                position:absolute;
                top:0;
                left:0;
                z-index:-1;
                /*display:none;*/
            }

        .four{
            float:left;
            width:25%;
        }

        .five{
            float:left;
            width:20%;
        }

        .key.right{
            position:absolute;
            opacity:.7;
            top:130px;
            left:50%;
            margin-left:-97px;
        }
        .key.inline{
            position:absolute;
            top:-52px;
            right:10px;
        }
        .key.head2{
            margin:0;
            padding:0;
            position:relative;
            top:3px;
            left:8px;
            display:none;
        }
        .key li{
            list-style:none;
            margin-bottom:5px;
        }
        .key.inline li{
            float:left;
            margin-right:15px;
        }
        .key span.square{
            display:block;
            float:left;
            width:18px;
            height:18px;
            border-radius:3px;
            margin:1px 10px 0 0;
        }

.openissues {
  float: right;
  margin: 2px 0 0 5px;
  font-style: normal;
}

.typeselect {
  padding: 1em 1em 1em 7em;
  display: block;
  background-image: linear-gradient(-180deg, #FFFFFF 0%, #E4E4E4 100%);
  border: 1px solid #CCCCCC;
  box-shadow: inset 0 0 2px 2px #FFFFFF;
  border-radius: 5px;
  position: relative;
  margin-bottom: 1em;
  text-shadow: -2px 2px 0 #ffffff;
  color: $grayReg;
  h3{
    font-size: 1.2em;
    color: $purpleLight;
    margin-bottom: 5px;
  }
  p {
    font-size: .9em;
    margin: 0;
  }
  span {
    @extend .vertical-align-ab;
    line-height: 64px;
    height: 64px;
    left: .4em;
    font-size: 4em;
    color: $purpleLight;
  }
}

.ticketdata {
  border: 1px solid $grayLighter;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  h3{
    padding: 10px;
    background: #f3f3f3;
    color: $purpleReg;
    border-bottom: 1px solid $grayLighter;
    margin: 0;
  }
  p {
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid $grayLighter;
    font-size: 14px;
    textarea {
      margin: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

/*** ACCOUNT POPUP SUCCESS ***/

.muchsuccess {
  padding-top: 200px;
	position:relative !important;
	text-align:center;
	padding:0;
	overflow: hidden;
	margin:20px 0 0 0;
	h2 {
    color:$purpleReg;
    margin:16px 0;
    font-size: 28px;
    text-align:center;
  }
	.successburst{
		position:relative;
		left:50%;
		margin-left:-90.5px;
	}
}

.successburst {
	color: #ffffff;
	position: absolute;
	width: 181px;
	height: 181px;
	text-align: left;
	left: 64px;
	top: 0;
	opacity: 0;
	-webkit-animation: myship 1s;
	-webkit-animation-fill-mode:forwards;
}

.successburst:before {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 181px;
    z-index: 1;
    color: $purpleReg;
    font-size: 181px;
    font-family: 'primoadmin';
    content: "\e903";
    -webkit-animation: spinX 1s;
}

@-webkit-keyframes spinX {
    0%   {-webkit-transform: rotate(90deg); -webkit-transform-origin: 50%;}
    100% {-webkit-transform: rotate(0deg); -webkit-transform-origin: 50%;}
}

@-webkit-keyframes myship {
	from {top: 30px; opacity: 0;}
	to{top:0px; opacity: 1;}
}

.successcheck {
	position: absolute;
  width: 181px;
	height: 181px;
	display: block;
}

.successcheck:before {
	position: absolute;
	width: 181px;
	height: 181px;
	text-align: center;
  line-height: 181px;
  z-index: 1;
  color: #ffffff;
  font-size: 120px;
  font-family: 'primoadmin';
	content: "\e902";
}


.thumbnail{
    position:relative;
}
.thumbnail p{
    position:absolute;
    background:rgba(0,0,0,0.65);
    bottom:4px;
    left:4px;
    right:4px;
    display:block;
    display:block;
    padding:0 10px;
    line-height:30px;
    color:rgba(255, 255, 255, 0.55);
    margin:0;
    box-sizing: border-box;
    overflow:hidden;
}
.thumbnail p:hover{
    background:rgba(0,0,0,1);
}
.thumbnail p span, .thumbnail p small{
    float:left;
    clear:left;
}
.thumbnail p span{
    color:#b489e5;
    position:relative;
    top:6px;
}
.thumbnail p small{
    position:relative;
    top:-2px;
}

.bottompop, .activepop{
    z-index:99999;
    position:fixed;
    bottom:10px;
    right:-380px;
    padding:10px;
    color:#fff;
    border-radius:6px;
    background:rgba(0,0,0,0.8);
    width:380px;
    opacity:0;
}

footer {color: #676767; font-size: 14px;
    a{
        color:#676767;
    }
    a.helpfile{
        font-size:1.7em;position:relative;top:-6px;color:#fff;z-index:99;
        &:hover{color:#ff6666;}
    }
}

.query_log{
    display:none;
    background:#020202;
    color:#898989;
    padding:0;
    margin:20px 0 0 -30px;
    width:120%;

    div{
        position:relative;
        padding:14px 0 14px 70px;
        border-bottom:1px solid #232323;
        overflow:hidden;

        span{
            position:absolute;
            left:0px;
            top:1px;
            height:100%;
            border-right:1px solid #232323;
            padding:13px 0 0 20px;
            width:26px;
        }

        p{
            color:#343434;
            margin:0;
        }

        &:hover{
            background:#121212;
        }
    }
}

/* Side Stats */
.sideStats{
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:375px;
    z-index:1000;
    background:rgba(46, 46, 46, 0.9);
    border-right:1px solid #1c1c1c;
    box-sizing:border-box;
    padding:24px 34px;
    color:#c9c9c9;
    line-height:35px;
}

.sideStats a.logo{
    background:url(/images/logo-stream.png);
    background-size: 176px 96px;
    position:relative;
    height: 28px;
}

.sideStats ul{
    list-style:none;
    border-top:1px solid #474747;
    border-bottom:1px solid #474747;
    margin:24px 0 14px 0;
    padding:14px 0;
}

.sideStats ul li{
    margin:0;
    padding:0;
    color:#fff;
    font-size:1.6em;
    line-height:50px;
}

.sideStats ul li span.counts{
    color:#656565;
    transition: color 1s;
}

.sideStats span.clrcirc{
    width:30px;
    height:30px;
    border-radius: 15px;
    background:#fff;
    display:inline-block;
    position:relative;
    top:5px;
}
    span.clrcirc.purple{background:rgb(134,82,174);}
    span.clrcirc.blue{background:rgb(70,169,200);}
    span.clrcirc.green{background:rgb(65,214,158);}
    span.clrcirc.orange{background:rgb(215,174,77);}
    span.clrcirc.pink{background:rgb(215,77,168);}
    span.clrcirc.yellow{background:rgb(255,252,0);}
    span.clrcirc.white{background:rgb(255,255,255);}
    span.clrcirc.gray{background:rgb(123,123,123);}

span.clrcirc.pulse{
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: 1;
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(1, 1); opacity: 1;}
    50% {-webkit-transform: scale(1.5, 1.5); opacity: 0.5;}
    100% {-webkit-transform: scale(1, 1); opacity: 1;}
}

.sideStats h4{
    color:#757575;
    font-size:1.6em;
    border-bottom:1px solid #474747;
    padding-bottom:14px;
    margin-bottom:14px;
    line-height:24px;
}

.missing{background:#ffe8e8;}

/*** FILE UPLOADER ***/

.jobUploads {padding: 20px;}
    .productsection.jobUploads{padding:20px 20px 0 20px;}
    .jobUploads sup {vertical-align: super; font-size: 10px}
    .jobUploads .button {color: #ffffff; background:$purple; padding: 7px 15px 3px; font-size: 15px; line-height:20px; margin-bottom: 10px; display: inline-block; @include border-radius(3px);}

.uploadSteps { width: 100%; margin-bottom: 20px; display: block;}

    .uploadLeft {width: 35%; padding: 0 20px 0 0; float: left; text-align: center; color: #000000;}
        .uploadLeft div.filePreview, .uploadLeft div.directions{display:none;}
            .uploadLeft.showDirections div.directions{display:block;}
            .uploadLeft.showPreview div.filePreview{display:block;}
        .uploadLeft h3 {font-weight: bold; font-size: 21px}
        .uploadLeft p {color: #000000; font-size: 14px; line-height: 16px}
        .uploadLeft img {display: inline-block; margin-bottom: 10px}
        .styleOne {margin-bottom: 10px; font-size: 14px}
            .styleOne span {color: $blueReg; font-size: 16px;}
        .styleTwo {margin-bottom: 15px}
        .styleThree {font-size: 11px; color: #888888;}

        .preview {width: 100%; /*max-width: 314px;*/ height: auto; position: relative; z-index: 1020}
            .preview img {height: auto; max-width: 100%; width: 100%; display: block; margin: 0;}
            .previewLine {position: absolute;}

            .preview{
      				.hangtaghole {
      					display: none;
      					min-height: inherit;
      					min-width: inherit;
      					.drillsafe {
      						border: 1px solid green;
      						width: 160%;
      						height: 160%;
      						display: block;
      						border-radius: 100px;
      						@extend .center-align-ab;
      						@extend .boxsize;
      					}
      				}
      				&.vertical{
      					.hangtaghole {
      						display: block;
      						width: 6.9%;
      						height: auto;
      						@extend .horizontal-align-ab;
      					}
      				}
      				&.horizontal{
      					.hangtaghole {
      						display: block;
      						height: 6.9%;
      						width: auto;
      						@extend .vertical-align-ab;
      						img {height: 100%; width: auto}
      					}
      				}
      			}

            .heightauto {height: auto !important}
            .widthauto {width: auto !important}

            .previewMask1, .previewMask2 {z-index: 1001; opacity: .6; position: absolute;}
            .previewMask1, .previewMask2, .previewBleed {border: 1px solid red; width: 100%; background-position: center center; background-repeat: no-repeat;z-index: 1000}

            .previewTrim {border: 1px solid blue; z-index: 1002; top: 0; left: 0;}
            .previewSafe {border: 1px solid green; z-index: 1003; top: 0; left: 0;}
            .previewLabel {font-size: 16px; color: #000000; text-align: center; font-weight: bold; padding: 10px 0; clear: both}

            .preview-flip-container .previewTrim { border: none; @include box-shadow(0, 0, 10px, #cccccc); background-position: center center; background-repeat: no-repeat;z-index: 1000}
            .preview-flip-container .previewMask1, .preview-flip-container .previewMask2 {border: none;}

            .preview-flip-container, .previewMargins {padding-bottom: 20px}

            .previewControls {text-align: left; margin-bottom: 22px}
            .previewControls .viewbutton {border: 1px solid #cccccc; cursor: pointer; margin-bottom: 0; text-align: center}
            .previewControls .viewfront {margin-right: 4%;}
            .previewControls .viewfront, .previewControls .viewback {width: 48%;}

            .previewPrint .flip-container, .previewPrint .front, .previewPrint .back {width: 100%; height: auto;}
            .previewPrint .front { position: relative; top: 0; left: 0;}

            .previewViews {@extend .noselect;}
            .previewViews label {width: 100%; text-align: left; font-size: 18px; padding-bottom: 5px}
            .previewViews label span {margin: -5px 0 0 0 !important;}
            .previewViews button {margin-right: 10px}

            .jobfilesActions {float: right;}

            .previewLegend {width: 100%;}
                .previewLegend li {width: 33%; float: left; text-align: left; line-height: 18px; height: 40px; padding: 10px 0; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; margin: 0;}
                .previewLegend li span {width: 18px; height: 18px; display: inline-block; margin: 0 5px -4px 0;}
                .bleedBox {background-color: red}
                .trimBox {background-color: blue}
                .safeBox {background-color: green}

        .filePreview h4 {text-align: left; padding: 20px 0; font-size: 19px; color: #7c7c7c;}

        .halfsize{width:47% !important;}

    .uploadRight {width: 65%; float: right;}

    .fileActions a{display:none;}

    .fileUpload {border: 1px solid #ddd; margin: 0 0 10px 0;  position: relative; background: #fff; padding: 0 0 0 70px; min-height: 69px;}
        .fileUpload {cursor: pointer;}
        .fileUpload:hover { background: #f3f3f3;}
        .fileUpload input {position: absolute; width: 100%; height: 100%; opacity: 0; top: 0; left: 0; margin: 0; z-index: 1001; cursor: pointer;}
        .upload-message {color: #000; margin:2px 0 24px 0;font-size:1em;}
            .upload-message a {color: $blueReg;}
            .upload-message span.label{ position: absolute; bottom:11px; left: 10px; opacity:0.85; display:none; z-index:9999;}
            .label-success{background: #f79808!important;}
            .label-otp{background:$greenReg !important;}
            .label-primary{background:$blueReg !important;}
            .label-info{background:$blueReg !important;}
            .label-warning{background:$warning !important;color:#fff !important;text-shadow:none !important;}

        .fileUpload .icon, .overlay {position: absolute; width: 70px; line-height: 70px; height: 100%; top: 0; left: 0; z-index: 1;}
            .fileUpload .icon div {position: absolute; width: 70px; height: 70px; top: 0; left: 0; margin-top:4px; text-align: center; color: #ffffff; font-size: 35px}
        .fileUpload .waiting {background: #cccccc;}
        .fileUpload .uploading {background: $blueReg;}
        .fileUpload .warning {background: #e7c900;}
        .fileUpload .approved {background: #C6E493;}
        .fileUpload .pending {background: #c6e493;}
        .fileUpload .failed {background: $attention;}
        .fileUpload span {font-weight: bold;}

        .overlay{left:-70px;text-align:center;color:#fff;font-size:2em;line-height:80px;display:none;}
            .overlay.color-important{background:#c97272;}
            .overlay.color-success{background:#f79808;}
            .overlay.color-otp{background:#b3d673;}
            .overlay.color-info{background:#6fa9e2;line-height:77px;font-size:1.9em;}
            .overlay.color-warning{background:#a67cd6;}

        .filepending span.label-warning, .filepending span.color-warning{display:block !important;}
        .proof span.label-info, .proof span.color-info{display:block !important;}
        .rejected span.label-important, .rejected span.color-important{display:block !important;}
        .success span.label-success, .success span.color-success{display:block !important;}
        .oktoprint span.label-otp,  .oktoprint span.color-otp{display:block !important;}
        .filepending div.fileActions, .proof div.fileActions{}

        .uploaderWrap {padding: 0; position: relative;}
        .uploaderWrap .progress {margin: 0; height: 15px;}
        .upload-message {padding-right: 80px;}
        .uploaderWrap {padding: 0;}
        .sr-only {
            color: #000;
            z-index: 1002;
            position: absolute;
            width: auto;
            height: auto;
            margin: 0;
            padding: 0;
            overflow: hidden;
            clip: auto;
            border: 0;
            top: 12px;
            right: 10px;
        }

        .icon-spinner-2:before {-webkit-animation: rotation 3s infinite linear; display: inline-block;}
        @-webkit-keyframes rotation { from {-webkit-transform: rotate(0deg);} to {-webkit-transform: rotate(359deg);}}

        .removeFile {position: absolute; right: 10px; @extend .vertical-align; width: 16px; height: 16px; line-height: 16px; color: #aeaeae; cursor: pointer;}
        .removeFile:before {}

        .fileActions { margin:0 15px 16px 0; /*padding: 10px 0 0 0; border-top: 1px solid #ccc;*/position:relative;top:-8px;}
            .fileActions h4 {margin:0 0 7px 0;padding:0;font-size:0.9em; color:$blueReg;}
            .fileActions ul {margin: 0; list-style:none; padding: 0 0 10px 0;}
            .fileActions li {font-size: 13px; margin: 0; line-height: 20px; position: relative; padding-left: 10px}
            .fileActions li:before {content: "• "; color: #e7c900;}
            .fileActions .faileddesc li:before {content: "• "; color: $attention;}
            .fileActions li:before, .fileActions .faileddesc li:before {position: absolute; left: 0;}
            .fileActions a {color: $blueReg;}
            .fileActions .button {margin: 0 10px 10px 0; font-size: 13px !important; height: 25px; border: 1px solid #cdcdcc;}
            .fileActions .button:last-child {margin: 0;}
            .button {@extend .noselect;}
            .button.nocolor {background: #f5f5f5; color: #000;}
            .button.nocolor:hover {background: #ffffff;}
            .button.errorcolor {background: #ffc4c4; color: #000;}
            .button.errorcolor:hover {background: #ffd6d6;}
            .button .removeFile {margin-left: 0px; font-size: 16px}
            .button sup {margin-top: -5px; display: inline-block;}
            .dateDelay {font-size: 11px; clear: both; padding: 10px 0 0 0;}
            .deleteCommon {position: absolute; top: 0; left: 0; background: #ffffff; width: 100%; height: 100%; z-index: 99999; padding-top: 17px}
            .deleteCommon .button {margin-bottom: 0}
            .commonFile {float: left; line-height: 33px; font-size: 14.5px; margin: 2px 10px 5px 0; color: #000; font-weight: bold;}

        .uploadStep1, .uploadApproved {padding: 24px 10px; position: relative;}
        .uploadStep1 form{margin:0;}
        .uploadVerify {padding: 4px 0;}
        .uploadVerify .button {margin: 3px 0;}
        .uploadStep2 {position: relative;padding:12px 10px;}

        .waiting, .uploading, .warning, .approved, .failed, .jobUploads .pending, .uploadStep1, .uploadStep2, .uploadStep3, .uploadWarning, .uploadPending, .uploadCommon, .uploadVerify, .uploadApproved, .uploadFailed {display: none;}

        .stepUpload .waiting, .stepUpload .uploadStep1 {display: block; }
        .stepUploading .uploading, .stepUploading .uploadStep2 {display: block;}
        .stepWarning .uploadStep3, .stepWarning .warning, .stepWarning .uploadWarning {display: block;}
        .stepPending .uploadStep3, .stepPending .pending, .stepPending .uploadPending {display: block;}
        .stepCommon .uploadStep3, .stepCommon .pending, .stepCommon .uploadCommon {display: block;}
        .stepVerify .uploadStep3, .stepVerify .approved, .stepVerify .uploadVerify {display: block;}
        .stepApproved .uploadStep3, .stepApproved .approved, .stepApproved .uploadApproved {display: block;}
        .stepFailed .uploadStep3, .stepFailed .failed, .stepFailed .uploadFailed {display: block;}

        .uploadPending, .uploadVerify, .uploadWarning, .uploadCommon, .uploadFailed{padding:16px 14px;}

        .oktoprint a.approveSingle{display:none !important;}

        .fileControl{
            clear:both;
            float:left;
            width:100%;
            position:absolute;
            right:0;
            bottom:0;
            line-height:auto;
            background:#f7f7f7;
            border-top:1px solid #eee;
            padding:8px;

            a{
                float:right;
                height:18px;
                margin:0 0 0 6px;
                position:relative;
                top:0;
                right:0;
                bottom:0;
                left:0;
                line-height:18px;
                width:auto;

                &.removeFile{
                    top:12px !important;
                }
            }
        }

        .stepUpload p.upload-message{margin-bottom:2px;}
        .stepUploading p.upload-message{margin-bottom:10px;}

        .dz-preview{display:none;}
        .dz-drag-hover{background:#e6f4cb;}

        .toggleList label{line-height:28px;font-size:1em;}
        .btn-group button{outline: 0 !important;border-radius:0 !important;}
        .roundGrp button:first-child{border-radius:5px 0 0 5px !important;}
        .roundGrp button:last-child{border-radius:0 5px 5px 0 !important;}
        .roundGrp button {
          background: #ccc;
          color: #fff;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
        .roundGrp button.active {
          background: $secondaryColor;
          color: #fff;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
        .toggleBlock{float:left;clear:left;margin-bottom:5px;width:100%;padding:5px 10px 0px 10px;box-sizing: border-box;}
        .toggleBlock:nth-child(even){background:#efefef;}

    .adminBar div{padding:10px;}
        .adminBar h1{margin:0;padding:0;line-height:38px;}

    .attention {background: #f3f0ba; padding: 8px 5px 8px 40px; @include border-radius(); position: relative; margin-bottom: 20px}
        .attention .notification {width: 20px; height: 20px; font-size: 20px; line-height: 20px; color: #bfbc83; position: absolute; left: 10px;}
        .attention a {color: $blueReg;}

.cannedResponses{
    position:absolute;
    width:450px;
    height:200px;
    background:#F8F8F8;
    border:1px solid #DDDDDD;
    z-index:9999;
    border-radius:5px;
    -webkit-box-shadow: 0px 0px 27px 7px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 0px 27px 7px rgba(0,0,0,0.18);
    box-shadow: 0px 0px 27px 7px rgba(0,0,0,0.18);
    overflow-x:hidden;
    overflow-y: auto;
    ul, li{list-style: none;}
    ul{
        margin:0;
        padding:0;
        li{
            margin:0;
            padding:0;
        }
        li, a{
            font-weight:bold;
            text-decoration: none;
            color:#8250B6;
            display:block;
            width:100%;
            height:35px;
            line-height:35px;
            border-bottom:1px solid #dedede;
            text-decoration: none;
        }
        a{
            padding:0 10px;
            &:focus, &.active{
                outline: none;
                background-color:#dedede;
            }
        }
    }
}

.reportcharts {
  ul {margin: 0; padding: 0}
  canvas {width: 100% !important; height: auto !important;}
}

.center-align {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-fix {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-ab {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1024px), (max-device-width: 1024px) {
     #mainarea {padding-left: 0; width:100%;}
     .jobActions {position: relative; width: 100%; height: 27px; left: 0; border-radius: 3px; margin-bottom: 15px;}
     .jobActions a.jobdelete, .jobActions a.edit { text-indent: 0; background: none; width: 50%; text-align: center; float: left; position: relative; top: 0; line-height: 27px; height: 27px; color: #ffffff;}
     .jobActions a {margin-top: 0}

     #contentTop .orderActions {
       float: left;
       padding-top: 15px;
       clear: both;
       display: block;
       a, .directConnect {margin: 0 10px 10px 0; float: left; font-size: 11px}
     }

     .orderAlerts {padding-top: 10px;}

     .refstatus {
       padding-top: 0px;
     }

     .csrActions {
       a, .btn {font-size: 11px}
     }
}

@media only screen and (max-width: 900px), (max-device-width: 900px) {
    .deskRight, .deskLeft {width: 100%; display: block; float: none;}
    .deskRight {padding: 0 0 0 12px;}
    .deskRight a{float: left;}
    .uploadLeft {width: 45%;}
    .uploadRight {width: 55%;}
    #search input { width: 80px;}
}

@media only screen and (max-width: 800px), (max-device-width: 800px) {
    #contentTop .container { padding-left: 20px}
    #contentTop h1 small {position: relative; top: auto; left: auto; display: block; width: 100%}
    #orderdetails #contentTop .container { padding-left: 0}
    #modal, #confirm {position: absolute;}
    #widget { width: 50%;}
    #widget .dayone ul span {display: inline-block;}
    #widget .number, #widget .ammount {font-size: 17px}
    .customerinfo {width: 100%;}
    .jobspecs, .orderDrop .ordercodes {width: 100%;}
    .detailsLeft h2 .small {display: block;}
    .detailsRight {padding-top: 0}
    .ordercodes div input.span2 {padding: 8px 4px; width: 100px;}
    .uploadedFiles li .filename { clear: both; width: 75%; padding-left: 27px; overflow: hidden;}
    .paymentleft, .paymentright { width: 100%; text-align: right}
    .messagePanel {width: 100%;}
    .ordermodTitle {display: block; text-align: right;}
    .csractionsRight, .csractionsLeft {width: 100%; display: block; float: none;}
    .csractionsRight a, .csractionsLeft .headeraction {float: left; margin-right: 5px}
    .csractionsRight {
      float: right;
      .closeBtn, .expandall, .closeall, .saveall, .sendProofs {
        margin-left: 0;
      }
      a {margin-bottom: 10px}
    }
    .csractionsRight .headeraction {float: left;}
    .uploadLeft {width: 50%;}
    .uploadRight {width: 50%;}
    .previewViews {margin-bottom: 10px}
    .previewViews, .jobfilesActions {clear: both; float: left;}
    .previewViews .btn-group > .btn, .jobfilesActions a.btn {font-size: 9px; margin-right: 5px; padding: 1px 4px;}
    .widthfour, .widththree, .widthtwo, .widthone {width: 100%;}
}

@media only screen and (max-width: 620px), (max-device-width: 620px) {
    header {padding: 20px 10px;}
    #modal, #confirm {position: absolute;}
    #widget { width: 100%;}
    #widget .daytwo ul span {display: inline-block;}
    .logo, .sideStats a.logo {
      width: 28px;
      height: 28px;
      background-size: 370%;
      background-position: left bottom;
      border-radius: 30px;
      border: 2px solid #595959;
      background-position: bottom left;
      display: block;
      margin-right: 10px;
    }
      .logo:hover {border-color: #1799bd}
    .footable .btn { font-size: 12px; letter-spacing: 0}
    .textright {float: left; clear: both; padding-left: 37px}
    .totalCharged {display: block; width: 100%; margin-right: 0; text-align: right;}
    .csrActions .btn { font-size: 12px; padding: 4px 6px;}
    .csrActions .btn.right10 {margin-right: 5px}
    .csractionsRight {}
    .csractionsLeft {}
    .uploadLeft {width: 100%; clear: both; padding: 0;}
    .uploadRight {width: 100%; clear: both;}
}

@media only screen and (max-width: 481px), (max-device-width: 481px) {
  #search {
    display: block;
  }
}

@media only screen and (max-width: 480px), (max-device-width: 480px) {
    #modal, #confirm {position: absolute;}
    #widget .number, #widget .ammount {padding: 20px 5px;}
    .input-append.widthtwo, .input-append.widthone, .input-append.widththree { width: 50%;}
    .right2 { margin-right: 0;}
    .paymentleft, .paymentright { font-size: 14px}
    a.btn, button.btn {font-size: 12px}
    #widget.balanceWidget {
      width: 100%;
    }
    .navsearch {display: block !important}
    #search {
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .search-wrap {
        top: 50px;
        background: #1f1f1f;
        box-shadow: 0 1px 0 #696969;
        border: 10px solid #ffffff;
        width: 80%;
        padding: 5px 60px 5px 5px;
        position: fixed;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        .closeBtn {display: block; position: absolute; top: 5px; right: 5px}
      }
      input, input#typeahead .tt-query, input#typeahead .tt-input, .twitter-typeahead {width: 100% !important; margin: 0;}
      button{position: absolute; right: 30px; top: 6px}
    }
}

@media only screen and (max-width: 450px), (max-device-width: 450px) {
    #content .container {padding: 20px 3% 30px 3%;}
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
}

.tt-dropdown-menu, .tt-menu {
  min-width: 226px;
  font-size:0.9em;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.tt-dropdown-menu h3, .tt-menu h3{
    color:$primaryNavColor;
    margin:8px 0;
    padding:0 10px 10px 10px;
    font-size:1.1em;
    border-bottom:1px solid #ddd;
}

.tt-suggestion {
  display: block;
  padding: 6px 12px;
  cursor:pointer;
}

.tt-suggestion.tt-is-under-cursor, .tt-suggestion.tt-cursor {
  color: #fff;
  background-color: lighten($secondaryColor, 5%);
}
.tt-suggestion.tt-cursor,.tt-suggestion:hover {   color: #fff;   background-color: $secondaryColor  }
.tt-suggestion.tt-is-under-cursor a, .tt-suggestion.tt-cursor a {
  color: #fff;
}

.tt-suggestion p {
  margin: 0;
}

/* visuallyhidden */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* prevent animate */
.noanimate {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

/*** CLEARING, ANIMATE, ACCESSABILITY, GENERAL ***/

.customer-visible {color: $purpleLight}
.clear {clear: both;}
.break {border-top: 1px solid #cccccc; padding-top: 20px;}
.clearright {clear: right;}
.group:before,
.group:after {content:""; display:table;}
.group:after {clear:both;}
.group {zoom:1; /* For IE 6/7 (trigger hasLayout) */}
.noselect {-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;}
.animate2 {-webkit-transition: all .2s ease-in-out; -moz-transition: all .2s ease-in-out; -o-transition: all .2s ease-in-out; transition: all .2s ease-in-out;}
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}
.hide {display: none;}
.show {display: block !important;}
.invisible {opacity: 0}
.visible {opacity: 1}
.boxsize, .boxsize li, nav li, input, textarea {-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}
.relative {position: relative}
.noborder {border: none;}
.bodyblur {
    filter:blur(4px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(4px);
    -webkit-filter:blur(4px);
}
.textleft {text-align: left}

sup {color: $attention;}

ul.thumbnails{
    li{width:22.7%;margin-left:2% !important;}
    li:last-child{margin-right:0;}
    div.thumbnail{
        padding:0;
        overflow:hidden;
        background:#fff !important;
        text-align:center;
        h3{font-size:0.7em;font-weight:normal;padding:5px 0 5px 10px;margin:0;width:100%;text-align:left;text-transform: uppercase;background:#000;color:#fff;}
        span{
            color:#777 !important;display:block;width:100%;font-size:1.5em;padding:25px 0 13px 0;
            strong{font-size:10px !important;}
        }
        span.left{
            float:left;width:40%;background:#eaf5ff;
        }
        span.right{
            float:right;width:60%;background:#e2ffd6;
        }
    }
}

.logNav{
    width:16%;
    position:fixed;
    left:0;
    top:76px;
    bottom:0px;
    background:#212121;

    .errs a{
        width:25%;box-sizing:border-box;border-radius:0 !important;float:left;margin:0;height:44px;line-height:32px;
        &:nth-child(1){
            &:hover, &.selected{background-color: #c4c4c4;font-weight:bold;border-top:3px solid #a0a0a0;line-height:26px;}
        }
        &:nth-child(2){
            background-color: #26d68d;
            &:hover, &.selected{background-color: #3ceaa2;border-top:3px solid #29b279;line-height:26px;}
        }
        &:nth-child(3){
            background-color: #eaaa20;
            &:hover, &.selected{background-color: #ffc759;border-top:3px solid #cc9220;line-height:26px;}
        }
        &:nth-child(4){
            background-color: #d62a2a;
            &:hover, &.selected{background-color: #ea4444;border-top:3px solid #b73131;line-height:26px;}
        }
    }

    .logNavLink{
        box-sizing:border-box;border-radius:0 !important;float:left;margin:0;height:40px;line-height:26px;
        width:100%;
        background-color:#6D37A4;
        &:hover, &.selected{background-color:#8856bf;}
    }
}

.logList{
    width:84%;
    margin-left:16%;

    tr th{
        text-align: left;
        height:44px;
        background:#ddd;
        padding:0 20px;
    }

    tr td{
        padding:7px 20px 7px 20px;
        transition: background-color 1000ms linear;
        -webkit-transition: background-color 1000ms linear;
        -moz-transition: background-color 1000ms linear;
        -o-transition: background-color 1000ms linear;
        -ms-transition: background-color 1000ms linear;
    }

    tbody tr:nth-child(even) td{
        background:#f8f8f8;
    }

    tbody tr.highlight td{
        background-color: #ffe27c;
    }
}

.restricted{background:#CFCFCF !important;}

// EDDM
.productheader {
    padding: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    color: #fff;

    h2 {color: #ffffff; font-size: 19px; margin-bottom: 5px}
    p {color: #ffffff; font-size: 15px}
}

.productheader.setheader {background: $purpleReg;}

	/** Modal **/
	.mapModal{
		position: fixed;
		top:60px;
		left:50px;
		right: 50px;
		bottom: 50px;
		box-sizing: border-box;
		z-index: 9999;
		background: rgba(0, 0, 0, 0.8) url('/images/loading.svg') no-repeat center center;
		border-radius: 8px;
		transition: opacity .5s;

		a.btn{
			background: $greenReg;
            color: #fff;
			height: 18px;
			line-height: 18px;
			margin: 8px 0 8px 12px;
			font-weight: bold;
			transition: all .5s;
            border: none;

			&:nth-child(2){
				float:right;
				margin: 8px 12px 8px 0;
			}

			&:hover{
				color: #fff;
				background: $purpleLight;
			}

			&.hide{
				opacity: 0;
			}
		}

		.route_functions{
			position: absolute;
			display: block;
			height: 50px;
			z-index: 3;
			top:54px;
			left: 10px;
			width: 290px;
			background: $blueReg;
			overflow: hidden;

			.login_buttons{
				display: none;
				a{
					float:left;
					margin-left: 10px;
				}
			}

			&.login .login_buttons{
				display: block;
			}

			.route_buttons{
				position: absolute;
				margin-left: -289px;
				width: 100%;
				transition: all .3s;
				text-align: center;
				a{
					margin-left: 0;
				}
			}

			.route_save{
				position: absolute;
				margin-left: 289px;
				transition: all .3s;

				input{
					padding: 0 6px;
					margin: 8px 0 8px 8px;
					border: none;
					border-radius: 4px;
					height: 32px;
					width: 164px;
				}
				a{
					margin-left: 5px;
				}
				a.cancelSave{
                    margin-right: 0;
					background: $grayLight;

					&:hover{
						background: lighten(#ff5a45, 5%);
					}
				}
			}

			&.rButtons{
				.route_buttons{
					margin-left: 0;
				}
				.route_save{
					margin-left: 289px;
				}
			}

			&.rSave{
				.route_buttons{
					margin-left: -289px;
				}
				.route_save{
					margin-left: 0;
                    float: right;
				}
			}

		}

		.routes_list{
			position: absolute;
			z-index: 3;
			left: 10px;
			top: 104px; //54px
			bottom: 10px;
			width: 289px;
			border-right: 1px solid #A3A3A3;
			// border-top: 1px solid #fff;
			background: #D8D8D8;
			border-radius: 0 0 0 4px;
			overflow-x: hidden;
			overflow-y: auto;

			div.login{
				font-size: 1.1em;
				padding: 10px;
				display: none !important;
			}

			&.login div.login{
				display: block !important;
			}

			ul{
                list-style: none;
                margin: 0;
                padding: 0;
                font-size: 0.9em;

				li{
					position: relative;
					// border-top: 1px solid #fff;
					border-bottom: 1px solid #BABBBA;
					padding: 10px;
					margin: 0;
					cursor: pointer;
					transition: all .4s;

					&.tutorial{
						height: 100%;
						h4{
							margin: 7px 0;
							font-weight: normal;
							color: #212121;
						}
						span, a{
							display: none;
						}
					}

					a.opt{
						position: absolute;
						top: 10px;
						right: 10px;
						width: 22px;
						height: 22px;
						background: #BABBBA;
						border-radius: 4px;
						text-align: center;
						line-height: 22px;
						font-size: 0.8em;
						color: #fff;
						transition: background .5s;
						&.optGreen{
							right: 40px;
							background: $greenReg;
							display: none;
						}
						&.optRed{
							&:hover{
								background: lighten($attention, 10%);
							}
						}
					}

					&.active{
						background: #fff;
						a.optGreen{
							display: block;
						}
					}

					&:hover{
						background: #efefef;
					}

					h3, h4{
						margin: 0;
					}
					h3{
						font-size: 1.2em;
						color: $purpleReg;
					}
					h4{
						font-size: 1.1em;
						color: $blueReg;
					}
					& > span{
						display: inline-block;
						padding-right: 12px;
						font-size: 1em;
						color: #5B5B5B;
						& + span{
							padding-right: 0px;
						}
					}
				}
			}
		}

		iframe{
			box-sizing: border-box;
			position: absolute;
			padding:52px 10px 10px 300px;
			height: 100% !important;
			z-index: 1;
			opacity: 0;
			transition: all .5s;
            border: none;
		}

		.map{
			padding: 0;
			max-width: 100%;
			position: absolute;
			left: 10px;
			right: 10px;
			top:10px;
			.routeSelection{
				margin: 0;
				background: none;

				.productheader{
					border-radius: 0 4px 0 0;
					background: $blueReg;
					border-top: 1px solid lighten($blueReg, 15%);
					border-bottom: 1px solid darken($blueReg, 15%);
					border-left: 1px solid darken($blueReg, 15%);
					position: relative;
					z-index: 2;
					margin-left: 289px;
                    font-weight: bold;

					&:first-child{
						position: absolute;
						border-radius: 4px 0 0 0;
						z-index: 4;
						margin-left: 0;
						width:269px;
						border-left: 0;
					}

					a.mapCloseBtn{
						position: absolute;
						top: 0px;
						right: 0px;
						color: #fff;
						transition: color .5s;
						width: 40px;
						height: 40px;
						line-height: 42px;
						&:hover{
							color: $greenReg;
						}
					}
				}
			}
		}
	}

.responseText, .commentText {
  word-wrap: break-word;
}

.responseText {
  margin: 10px 10px 20px 10px;
  p {
    padding: 0 0 0 10px;
    border-left: 3px solid $grayLightest;
  }
}


  #charList {
    margin: 0;
    padding: 0 0 0 15px;
    border: none;
    display: none;
    .charitylisting {
      position: relative;
      @include border-radius(5px);
      border: 1px solid #e1e1e1;
      padding: 10px 10px 10px 120px;
      margin-bottom: 15px;
      overflow: hidden;
      @include box-shadow(0, 0, 5px, #e1e1e1);
      h2 {
        font-size: 1.1em;
        margin: 0 0 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal;
        a {color: $grayReg}
      }
      .charitylogo a {width: 90px; position: absolute; top: 10px; left: 10px}
    }
  }

  .nocharitylisting {
    display: none;
    position: relative;
    @include border-radius(5px);
    border: 1px solid #e1e1e1;
    padding: 10px;
    height: 90px;
    @include box-shadow(0, 0, 5px, #e1e1e1);
    h4 {margin-bottom: 8px; font-size: 18px; font-weight: 300; a {color: #F7941D}}
    p {font-size: 16px; margin-bottom: 8px; padding: 0}
    .regshare {
      a{
        padding: 3px 6px;
        line-height: 20px;
        height: 20px;
        font-size: 16px
      }
    }
    &.popup {
      h4 {font-size: 16px}
      p {
        font-size: 14px;
        a {color: #F7941D;}
      }
    }
  }

  .modal-dialog.charity-modal {
    @extend .boxsize;
    height: 90%;
    .badge {
        display: inline-block;
        min-width: 10px;
        padding: 3px 7px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        line-height: 1;
        vertical-align: baseline;
        white-space: nowrap;
        text-align: center;
        background-color: #999999;
        border-radius: 10px;
    }
    .btn-primary {
    color: #ffffff;
    background-color: #F7941D;
    border-color: #F7941D;
    line-height: 20px;
    height: 33px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
        color: #ffffff;
        background-color: #FFAB44;
        border-color: #FFAB44;
    }
    .btn-default {
        color: #ffffff;
        background-color: #F7941D;
        border-color: #cccccc;
    }
    .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
        color: #ffffff;
        background-color: #FFAB44;
        border-color: #cccccc;
    }
    .modal-title {margin: 0;line-height: 1.428571429;}
    .modal-content {height: 100%; padding: 56px 0 73px; @extend .boxsize; position: relative;}
    .modal-body {height: 100%; @extend .boxsize; padding: 15px 15px 15px 0;}
    .modal-header {position: absolute; top: 0; width: 100%; @extend .boxsize;}
    .modal-header .close {margin-top: -2px; color: $grayLight;}
    button.close {padding: 0; cursor: pointer; background: transparent; border: 0;-webkit-appearance: none;}
    .close {float: right; font-size: 28px; font-weight: bold; line-height: 1; color: #000000;}
    .modal-footer {position: absolute; bottom: 0; width: 100%; @extend .boxsize;}
    form.charitysearch {margin-bottom: 0}
    li:nth-child(even){background: #ebebeb;}
    .btn-green {background: #F7941D;}
    .btn-green:hover {background: #FFAB44;}

    #charList .charitylisting {
      a h2 {color: $grayReg; margin: 0 0 5px 0;}
      padding: 10px;
      p {font-size: 13px; color: $grayReg; line-height: 15px; font-weight: normal; height: 30px; margin-bottom: 5px}
    }
  }

  .featured-charity {
    margin-top: 35px;
    .charitylisting {
      width: 30%;
      @include border-radius(5px);
      border: 1px solid #e1e1e1;
      @include box-shadow(0, 0, 5px, #e1e1e1);
      overflow: hidden;
      @extend .boxsize;
      float: left;
      margin-right: 5%;
      height: 300px;
      text-align: center;
      &:last-child {margin-right: 0;}
      padding: 0;
      .featuredheader {
        position: relative;
        height: 200px;
        .charity-header {
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
        }
				.charitylogo {
					position: absolute;
					width: 100%;
					height: 100%;
				}
        .charitylogo a {
          @include border-radius(5px);
          width: 110px;
          @extend .center-align-ab;
        }
      }
      .featuredcontent {
        padding: 10px;
        p {
          overflow:hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: "…";
        }
        h2 {
          margin: 5px 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          a {color: $grayReg;}
        }
      }
    }
  }

  form.charitysearch {
    position: relative;
    margin-bottom: 45px;
    &:before {
      @extend .vertical-align-ab;
      left: 10px;
      font-size: 25px;
      line-height: 25px;
      padding-top: 1px
    }
    input {
      border: none;
      @include border-radius(3px);
      border: 1px solid #cacaca;
      line-height: 30px;
      height: 55px;
      display: block;
      width: 100%;
      padding: 5px 9px 5px 45px;
      background-position: 7px center;
      font-size: 25px;
      background: #ffffff;
      background-image: none;
      @extend .boxsize;
      font-family: 'Helvetica', 'sans-serif';
    }
  }

  .search-pagination {
    text-align: center;
    line-height: 32px;
    padding: 35px 0 0;
    a {
      color: $grayReg;
      border: 1px solid #e1e1e1;
      padding: 5px 10px;
      @include border-radius(20px);
      font-weight: 400;
      &.selected {
        @extend .disabled;
      }
      &.selected, &:hover {
        color: #ffffff;
        background: $grayLight;
        border-color: $grayReg;
      }
    }
  }

  //Charity Detail
  .charitydetail {
    a {color: #F7941D}
    .charity-header{
      padding: 20px 0;
      background-size: cover;
      background-position: center;
      height: 300px;
      &.nobg {height: 20px}
    }
    .charity-header-wrap {
      padding: 10px 0;
    }
    .charitylogo a {
      background: #ffffff;
      @include border-radius(5px);
      margin-top: -125px;
      width: 150px;
      border: 1px solid #e1e1e1;
    }
    .defaultbtn {
      margin-top: -50px;
    }
    .defaultcharity {
      line-height: 33px;
      font-weight: 400;
      font-size: 18px;
      height: auto;
    }
    textarea {
      width: 100%;
      @extend .boxsize;
      @include border-radius(5px);
      padding: 10px;
      border-color: #e1e1e1;
      color: $grayReg
    }
    .badge {
      color: #ffffff;
      padding: 5px 10px;
      background: #ec008c;
    }
  }

    form.icon-search-thin{
        position:relative;
        .resLoading{position:absolute;right:45px;top:15px;display:none;}
        .clearRes{
            display:none;
            position:absolute;
            background: #CECECE;
            padding: 6px 8px;
            border-radius: 20px;
            font-weight: 400;
            color: #fff;
            top: 9px;
            right: 10px;
            &:hover{
                background:#F7941D;
            }
        }
    }

.boxsize, .boxsize li, nav li, input, textarea {-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}

label.file_options {display:inline-block;padding-right: 5px;}

.imgUp{width:100%;border:1px solid #efefef;padding:10px;margin-bottom:6px;line-height:10px;border-radius:6px;}
.addImage{margin:5px 0;font-size:12px;}
.imgUpText{font-size:14px;}


// SITE BANNER ALERTS CREATE/EDIT ERROR
.alert-error-date {
	display: flex;
	font-size: 12px;
	position: absolute;
	left: 15px;
	bottom: 70px;
	color: #ff0000;
}

.close-filter{
  display: inline;
  cursor: pointer;
  color: #8553BA;
  font-size: 0.8em;
  margin-left: 4px;
  position: relative;
  top: -1px;
}
