.tour-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background-color: #000;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.tour-step-backdrop {
  position: relative;
  z-index: 1101;
  background: inherit;

  > td {
    position: relative;
    z-index: 1101;
  }
}

.tour-step-background {
  position: absolute !important;
  z-index: 1100;
  background: inherit;
  border-radius: 6px;
}
.popover{
  min-width:400px;

  button{
    border-radius:4px;
  }
  .btn-group button:first-child{
    border-radius:4px 0 0 4px !important;
  }
  .btn-group button:last-child{
    border-radius:0 4px 4px 0 !important;
  }
}
.popover[class*="tour-"] {
  z-index: 1100;

  .popover-navigation {
    padding: 9px 14px;

    *[data-role="end"] {
      float: right;
    }

    *[data-role="prev"],
    *[data-role="next"],
    *[data-role="end"] {
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }

  &.orphan {
    position: fixed;
    margin-top: 0;

    .arrow {
      display: none;
    }
  }
}
