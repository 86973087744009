@import url("//fonts.googleapis.com/css?family=Roboto:400,500,700");

/* FONTS */
@font-face {
  font-family: 'primoadmin';
  src:  url('/fonts/primoadmin.eot?w66aiq');
  src:  url('/fonts/primoadmin.eot?w66aiq#iefix') format('embedded-opentype'),
    url('/fonts/primoadmin.ttf?w66aiq') format('truetype'),
    url('/fonts/primoadmin.woff?w66aiq') format('woff'),
    url('/fonts/primoadmin.svg?w66aiq#primoadmin') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
    font-family: 'primoadmin';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position:relative;
    top:1px;
    -webkit-font-smoothing: antialiased;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'primoadmin' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e902";
}
.icon-burst:before {
  content: "\e903";
}
.icon-wrench:before {
  content: "\e901";
}
.icon-bug:before {
  content: "\e900";
}
.icon-cancel-circle:before {
	content: "\21";
}
.icon-minus-circle:before {
	content: "\22";
}
.icon-plus-circle:before {
	content: "\23";
}
.icon-pencil:before {
	content: "\24";
}
.icon-envelop:before {
	content: "\25";
}
.icon-bubbles:before {
	content: "\26";
}
.icon-spinner:before {
	content: "\27";
}
.icon-zoomout:before {
	content: "\28";
}
.icon-zoomin:before {
	content: "\29";
}
.icon-remove:before {
	content: "\2a";
}
.icon-link:before {
	content: "\2b";
}
.icon-attachment:before {
	content: "\2c";
}
.icon-eye:before {
	content: "\2d";
}
.icon-warning:before {
	content: "\2e";
}
.icon-warning2:before {
	content: "\2f";
}
.icon-notification:before {
	content: "\30";
}
.icon-minus:before {
	content: "\31";
}
.icon-plus:before {
	content: "\32";
}
.icon-arrow-up:before {
	content: "\33";
}
.icon-arrow-right:before {
	content: "\34";
}
.icon-arrow-down:before {
	content: "\35";
}
.icon-arrow-left:before {
	content: "\36";
}
.icon-close:before {
	content: "\37";
}
.icon-lock:before {
	content: "\38";
}
.icon-unlocked:before {
	content: "\39";
}
.icon-cart:before {
	content: "\3a";
}
.icon-search:before {
	content: "\3b";
}
.icon-arrow-left2:before {
	content: "\3c";
}
.icon-arrow-down2:before {
	content: "\3d";
}
.icon-arrow-up2:before {
	content: "\3e";
}
.icon-uni3F:before {
	content: "\3f";
}
.icon-pencil2:before {
	content: "\40";
}
.icon-user:before {
	content: "\41";
}
.icon-cycle:before {
	content: "\42";
}
.icon-check-alt:before {
	content: "\43";
}
.icon-bubble:before {
	content: "\44";
}
.icon-link2:before {
	content: "\45";
}
.icon-apple:before {
	content: "\46";
}
.icon-chrome:before {
	content: "\47";
}
.icon-paypal:before {
	content: "\48";
}
.icon-credit:before {
	content: "\49";
}
.icon-switch:before {
	content: "\4a";
}
.icon-settings:before {
	content: "\4b";
}
.icon-heart:before {
	content: "\4c";
}
.icon-location:before {
	content: "\4d";
}
.icon-connection:before {
	content: "\4e";
}
.icon-floppy:before {
	content: "\4f";
}
.icon-print:before {
	content: "\50";
}
.icon-menu:before {
	content: "\51";
}
.icon-history:before {
	content: "\52";
}
.icon-mail-send:before {
	content: "\53";
}
.icon-barcode:before {
	content: "\54";
}
.icon-tags:before {
	content: "\55";
}
.icon-flag:before {
	content: "\56";
}
.icon-coins:before {
	content: "\57";
}
.icon-key:before {
	content: "\58";
}
.icon-vcard:before {
	content: "\59";
}
.icon-file-plus:before {
	content: "\5a";
}
.icon-file-download:before {
	content: "\5b";
}
.icon-support:before {
	content: "\5c";
}
.icon-filter:before {
	content: "\5d";
}
.icon-switch2:before {
	content: "\5e";
}
